import { observer } from 'mobx-react-lite';
import { IAddEditActionsProps } from './add-edit-actions.types';
import { useNavigate } from 'react-router-dom';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const AddEditActions = observer(({ type }: IAddEditActionsProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Button type="link" onClick={() => navigate(-1)} className="button">
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
        Save
      </Button>
    </>
  );
});
