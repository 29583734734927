import { App as CapacitorApp } from '@capacitor/app';

export const registerAndroidBackButtonListener = () => {
  CapacitorApp.addListener('backButton', ({ canGoBack }) => {
    if (canGoBack) {
      window.history.back();
    } else {
      CapacitorApp.exitApp();
    }
  });
};
