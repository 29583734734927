import { Button } from 'antd';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import { ReactComponent as LeftNavIcon } from 'assets/icons/eiq-connect/left-nav.svg';
import { ReactComponent as RightNavIcon } from 'assets/icons/eiq-connect/right-nav.svg';
import './image-gallery.scss';
import { useMedia } from 'common/hooks/use-media/use-media.hook';

const EIQImageGallery = (props: any) => {
  const { isMobile } = useMedia();

  const getImages = (urls: string[]) => {
    if (!urls) {
      return [];
    }

    return urls.map((i) => ({
      original: i,
      thumbnail: i,
      originalClass: 'eiq-image-gallery-image',
      thumbnailClass: 'eiq-image-gallery-thumbnail-image',
    }));
  };

  return (
    <div className="eiq-image-gallery">
      <ImageGallery
        showNav={!isMobile}
        showThumbnails={!isMobile}
        showBullets={isMobile}
        infinite={false}
        items={getImages(props.items)}
        renderLeftNav={(onClick, disabled) => {
          return <Button className="left-nav" onClick={onClick} disabled={disabled} icon={<LeftNavIcon />} />;
        }}
        renderRightNav={(onClick, disabled) => {
          return <Button className="right-nav" onClick={onClick} disabled={disabled} icon={<RightNavIcon />} />;
        }}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    </div>
  );
};

export default EIQImageGallery;
