import { EntryType } from 'common/enums/entry-type.enum';

export const entryTypeMap: Record<EntryType, string> = {
  [EntryType.Undefined]: 'Undefined',
  [EntryType.Driver]: 'Driver',
  [EntryType.Pedestrian]: 'Pedestrian',
  [EntryType.Passenger]: 'Passenger',
  [EntryType.Other]: 'Other',
};

export const entryTypeColorMap: Record<EntryType, string> = {
  [EntryType.Undefined]: '#CCCCCC',
  [EntryType.Driver]: '#C1FFEA',
  [EntryType.Pedestrian]: '#D1E3FF',
  [EntryType.Passenger]: '#FFD889',
  [EntryType.Other]: '#E1E5EA',
};
