import React from 'react';
import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GotToDetailsIcon } from 'assets/icons/eiq-connect/got-to-details.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/collect-key.svg';
import { ITrafficRead } from 'common/services/traffic-service/traffic-service.types';
import { admittanceTypeMap, admittanceWGColorMap } from 'common/constans/admittance.constants';
import { visitorTypeMap, visitorTypeMapColorMap } from 'common/constans/visitor.constants';
import { AdmittanceType } from 'common/enums/admittance-type.enum';
import { Tag } from 'antd';
import { DATE_SHORT_MMM_DD_YYYY, TIME_h_mma } from 'common/constans/date.constants';
import dayjs from 'dayjs';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { getTrafficLogGuestName } from 'common/helpers/traffic-log.helper';
import { TrafficLogVisitorType } from '../../../../common/constans/common.constants';

export const trafficListConfig = [
  {
    key: 'name',
    title: 'Name',
    dataKey: 'name',
    width: 700,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ITrafficRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <Tag
            className="eiq-tag"
            color={visitorTypeMapColorMap[rowData.visitorType as TrafficLogVisitorType]}
            style={{ color: '#292B2E' }}>
            {visitorTypeMap[rowData.visitorType as TrafficLogVisitorType]}
          </Tag>
          <div className="eiq-column__title">
            {rowData.providedKeyNumber && <KeyIcon />}
            {getTrafficLogGuestName(rowData)}
          </div>
          <div className="eiq-column__text" style={{ color: admittanceWGColorMap[rowData.admittanceType as AdmittanceType] }}>
            {admittanceTypeMap[rowData.admittanceType as AdmittanceType]}
          </div>
        </div>
      );
    },
  },
  {
    key: 'date',
    title: 'Date',
    dataKey: 'date',
    width: 150,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ITrafficRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      const CustomComponent = () => {
        const { isMobile } = useMedia();

        return isMobile ? (
          <div className="eiq-column">
            <div className="eiq-column__text">{dayjs(rowData.timestamp).format(DATE_SHORT_MMM_DD_YYYY)}</div>
          </div>
        ) : (
          <div className="eiq-column">
            <div className="eiq-column__text">Date</div>
            <div className="eiq-column__title">{dayjs(rowData.timestamp).format(DATE_SHORT_MMM_DD_YYYY)}</div>
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
  {
    key: 'time',
    title: 'Time',
    dataKey: 'time',
    width: 150,
    minWidth: 100,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ITrafficRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <div className="eiq-column__text">Time</div>
          <div className="eiq-column__title">{dayjs(rowData.timestamp).format(TIME_h_mma)}</div>
        </div>
      );
    },
  },
  {
    key: 'gate',
    title: 'Gate',
    dataKey: 'gate',
    width: 150,
    minWidth: 50,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ITrafficRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <div className="eiq-column__text">Gate</div>
          <div className="eiq-column__title">{rowData.gate}</div>
        </div>
      );
    },
  },
  {
    key: 'arrow',
    title: 'arrow',
    hidden: true,
    width: 15,
    minWidth: 15,
    cellRenderer: ({ rowData }: ICellRendererProps<ITrafficRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <GotToDetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/traffic/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
];
