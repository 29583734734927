import { ITrafficRead } from 'common/services/traffic-service/traffic-service.types';

import { TrafficLogVisitorType } from '../constans/common.constants';

export const getTrafficLogGuestName = (log: ITrafficRead | undefined | null) => {
  if (log?.visitorType === TrafficLogVisitorType.Resident) {
    return log?.residentName;
  }
  if (
    log?.visitorType === TrafficLogVisitorType.Service ||
    log?.visitorType === TrafficLogVisitorType.Delivery ||
    log?.visitorType === TrafficLogVisitorType.RideShare ||
    log?.visitorType === TrafficLogVisitorType.UnlistedService ||
    log?.visitorType === TrafficLogVisitorType.UnlistedDelivery ||
    log?.visitorType === TrafficLogVisitorType.UnlistedRideShare ||
    log?.visitorType === TrafficLogVisitorType.UrgentService ||
    log?.visitorType === TrafficLogVisitorType.UnlistedUrgentService
  ) {
    return log?.companyName + (log?.guestName ? ` (${log.guestName})` : '');
  }
  return log?.guestName;
};
