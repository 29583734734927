import React, { useState, useEffect } from 'react';
import { ICountdownTimerProps } from './сountdown-timer.types';
import { Spin } from 'antd';
import './сountdown-timer.scss';
import { formatDuration } from '../../helpers/date.helper';

const CountdownTimer: React.FC<ICountdownTimerProps> = ({ expires, expiredMessage }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [expired, setExpired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const difference = new Date(expires).getTime() - new Date().getTime();
      setTimeLeft(Math.floor(difference / 1000));

      if (difference <= 0) {
        clearInterval(intervalId);
        setTimeLeft(0);
        setExpired(true);
      }

      setLoading(false);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [expires]);

  if (loading) {
    return <Spin />;
  }
  return <>{expired ? <p className="expired-msg">{expiredMessage}</p> : <p className="left-time">{formatDuration(timeLeft)}</p>}</>;
};

export default CountdownTimer;
