import React from 'react';
import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { Button } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/eiq-connect/edit.svg';
import { useNavigate } from 'react-router-dom';
import { IPetRead } from '../../../../common/services/residents-service/residents.service.types';
import { ReactComponent as GotToDetailsIcon } from 'assets/icons/eiq-connect/got-to-details.svg';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import dayjs from 'dayjs';

export const petsListConfig = [
  {
    key: 'col1',
    title: 'Name',
    dataKey: 'col1',
    width: 195,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<IPetRead>) => {
      if (!rowData) {
        return <div></div>;
      }
      return (
        <div className="eiq-column">
          <div className="eiq-column__title">{`${rowData.name}`}</div>
          <div className="eiq-column__text">{`${rowData.color} ${rowData.breed}`}</div>
        </div>
      );
    },
  },
  {
    key: 'date',
    title: 'Date',
    dataKey: 'date',
    width: 150,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<IPetRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <div className="eiq-column__text">Shots expiration date</div>
          <div className="eiq-column__title">{dayjs(rowData.tagExpirationDate).format(DATE_SHORT_MMM_DD_YYYY)}</div>
        </div>
      );
    },
  },
  {
    key: 'action',
    title: 'Actions',
    width: 50,
    minWidth: 50,
    cellRenderer: ({ rowData }: ICellRendererProps<IPetRead>) => {
      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <Button
              size="large"
              className="round-primary"
              icon={<EditIcon />}
              onClick={() => {
                navigate(`/eiq-connect/pets/${rowData.id}/edit`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
  {
    key: 'arrow',
    title: 'arrow',
    hidden: true,
    width: 15,
    minWidth: 15,
    cellRenderer: ({ rowData }: ICellRendererProps<IPetRead>) => {
      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <GotToDetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/pets/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
];
