import { Space, Button, Modal, Image } from 'antd';
import { DetailRawView } from 'features/eiq-connect/components/detail-raw-view/detail-raw-view';
import { ReactComponent as EditIcon } from 'assets/icons/eiq-connect/edit.svg';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { IPetRead } from 'common/services/residents-service/residents.service.types';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import dayjs from 'dayjs';
import styles from './pet-details.module.scss';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';

export const PetDetails = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pet, setPet] = useState<IPetRead>();
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    if (!eiqConnectStore.residentDetails || !id) {
      return;
    }

    const pet = eiqConnectStore.residentDetails.addresses.flatMap((item) => item.pets).find((item) => item.id === Number(id));
    setPet(pet);
  }, [eiqConnectStore.residentDetails]);

  const formatAddress = useMemo(() => {
    if (!pet) {
      return '-';
    }

    const address = eiqConnectStore.residentDetails?.addresses.find((item) => item.pets.includes(pet))?.address;
    return `${address?.streetNumber} ${address?.street.name}`;
  }, [pet]);

  return (
    <div className="eiq-guests-page">
      <div className="eiq-header">
        <TopHeader>
          <Space size={10} className="eiq-action">
            <Button size="large" className="round-primary" onClick={() => navigate(`/eiq-connect/pets/${id}/edit`)} icon={<EditIcon />} />
          </Space>
        </TopHeader>
        <BottomHeader title="Details" />
      </div>

      <div className="eiq-content">
        <div className="w-100">
          <DetailRawView title="Address" value={formatAddress} />
          <DetailRawView title="Name" value={pet?.name} />
          <DetailRawView title="Type" value={pet?.type ? pet.type : '-'} />
          <DetailRawView title="Breed" value={pet?.breed} />
          <DetailRawView title="Color" value={pet?.color} />
          <DetailRawView title="Date of birth" value={pet?.dateOfBirth ? dayjs(pet.dateOfBirth).format(DATE_SHORT_MMM_DD_YYYY) : '-'} />
          <DetailRawView title="Tag" value={pet?.tag} />
          <DetailRawView title="Shots expiration date" value={dayjs(pet?.tagExpirationDate).format(DATE_SHORT_MMM_DD_YYYY)} />
          <DetailRawView title="Chip number" value={pet?.chipNumber ? pet.chipNumber : '-'} />
          {pet?.photoUrl && (
            <>
              <Image src={pet?.photoUrl} preview={false} onClick={() => setPreviewOpen(true)} wrapperClassName={styles.petImg} />
              <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt="pet-img" style={{ width: '100%' }} src={pet?.photoUrl} />
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
