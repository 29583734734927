import { Dayjs } from 'dayjs';
import { IPetFormData } from './pet-add-edit.types';
import { ICreatePet, IUpdatePet } from 'common/services/pet-service/pet-service.types';
import { IPetRead } from 'common/services/residents-service/residents.service.types';

const toStringDate = (date: Dayjs | null | '') => (date === '' || date === null ? null : date?.format('YYYY-MM-DD'));

export const toCreatePetModel = (values: IPetFormData): ICreatePet => {
  return {
    name: values.name!,
    photoUrl: values.photoUrl ?? '',
    type: values.type!,
    breed: values.breed!,
    color: values.color!,
    dateOfBirth: toStringDate(values.dateOfBirth),
    tag: values.tag!,
    tagExpirationDate: toStringDate(values.tagExpirationDate),
    chipNumber: values.chipNumber,
  };
};

export const toUpdatePetModel = (values: IPetFormData, pet: IPetRead): IUpdatePet => {
  return {
    id: pet.id,
    name: values.name!,
    photoUrl: values.photoUrl ?? '',
    type: values.type!,
    breed: values.breed!,
    color: values.color!,
    dateOfBirth: toStringDate(values.dateOfBirth),
    tag: values.tag!,
    tagExpirationDate: toStringDate(values.tagExpirationDate),
    chipNumber: values.chipNumber,
  };
};
