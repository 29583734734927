import React, { FC } from 'react';
import { Radio } from 'antd';
import { IRadioGroupProps } from './radio-group.types';

const RadioGroup: FC<IRadioGroupProps> = ({ onChange, value, options }, isVertical = true) => {
  const verticalStyles = isVertical ? 'vertical-radio-group' : '';

  return (
    <Radio.Group onChange={onChange} value={value} className={verticalStyles}>
      {options.map((item, index) => (
        <Radio value={item.value} key={`eiq-radio-group-${item.value}-${index}`}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioGroup;
