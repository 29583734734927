import { ServerClient } from 'common/utils/http/http-clients';
import { IFastAccessPassRead, IFastAccessPassDetails, ICreateFastAccessPass, ISendGuestFastAccessPass } from './fast-access-service.types';

class FastAccessService {
  async createFastAccessPass(data: ICreateFastAccessPass): Promise<IFastAccessPassRead> {
    const res = await ServerClient.post<IFastAccessPassRead>('/eiq-passport', data);
    return res?.data;
  }

  async sendGuestFastAccessPass(id: string, model: ISendGuestFastAccessPass): Promise<void> {
    const data = new FormData();
    data.append('file', model.file);
    data.append('contact', model.contact);
    await ServerClient.post<void>(`/eiq-passport/${id}/send`, data);
  }

  async getFastAccessPassDetails(id: string, baseUrl: string): Promise<IFastAccessPassDetails> {
    ServerClient.defaults.baseURL = baseUrl;
    const res = await ServerClient.get<IFastAccessPassDetails>(`/eiq-passport/${id}/details`);
    return res?.data;
  }
}

export const fastAccessService = new FastAccessService();
export default fastAccessService;
