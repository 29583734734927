import React from 'react';
import { Button, Space } from 'antd';
import { ReactComponent as LoginIcon } from 'assets/icons/eiq-connect/login.svg';
import { useNavigate } from 'react-router-dom';
import styles from '../forgot-password.module.scss';

const NewPassword = () => {
  const navigate = useNavigate();

  return (
    <Space direction="vertical">
      <div className="title">Password reset</div>
      <span className="text ant-mb-32">
        Your password has been successfully updated. <br /> Please log in from the button below.
      </span>

      <div className="action-line">
        <Button type="primary" onClick={() => navigate('/login')} icon={<LoginIcon />} className={styles.button}>
          Log in
        </Button>
      </div>
    </Space>
  );
};

export default NewPassword;
