import React, { FC } from 'react';
import { Button, Modal, Space, notification } from 'antd';
import { IModal } from 'common/models/model.interface';
import { ReactComponent as Trash } from 'assets/icons/eiq-connect/trash-white.svg';
import { IConfirmDeletePetProps } from './pet-delete-modal.types';
import petService from 'common/services/pet-service/pet-service';

const PetDeleteModal: FC<IModal<IConfirmDeletePetProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const handleDeletePet = () => {
    if (initData) {
      const { residentId, addressId, pet } = initData;
      petService.deletePet(residentId, addressId, pet.id).then((result) => {
        hideModal(true);
      });
    }
  };

  return (
    <Modal
      centered
      title={title}
      width={420}
      open={isOpen}
      wrapClassName="custom-modal"
      className="confirmation-modal vms-vehicle-delete-modal"
      closable={false}>
      <Space className="full-width" size={13}>
        <p>
          Are you sure you want to delete <span>{`${initData?.pet.name}`}</span> from a pet list?
        </p>
      </Space>

      <Space direction="vertical" className="full-width btn-container">
        <Button type="primary" className="full-width" icon={<Trash />} onClick={handleDeletePet}>
          Delete
        </Button>
        <Button type="link" className="full-width" onClick={() => hideModal(false)}>
          Cancel
        </Button>
      </Space>
    </Modal>
  );
};

export default React.memo(PetDeleteModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
