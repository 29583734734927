import { ServerClient } from 'common/utils/http/http-clients';
import { ICreateVehicle, IDeleteVehicle, IUpdateVehicle, IVehicleAdminRead } from './vehicle.service.types';

class VehicleService {
  async createVehicle(residentId: number, addressId: number, data: ICreateVehicle): Promise<ICreateVehicle> {
    const res = await ServerClient.post<ICreateVehicle>(`/residents/${residentId}/addresses/${addressId}/vehicles`, data);
    return res?.data;
  }

  async updateVehicle(residentId: number, addressId: number, data: IUpdateVehicle): Promise<IUpdateVehicle> {
    const res = await ServerClient.put<IUpdateVehicle>(`/residents/${residentId}/addresses/${addressId}/vehicles/${data.id}`, data);
    return res?.data;
  }

  async deleteVehicle(residentId: number, addressId: number, vehicleId: number, reason: IDeleteVehicle): Promise<any> {
    const res = await ServerClient.delete(`/residents/${residentId}/addresses/${addressId}/vehicles/${vehicleId}`, {
      data: reason,
    });
    return res?.data;
  }

  async getVehicles(pageLimit: any, filter: string | null | undefined, sort: string | null | undefined): Promise<IVehicleAdminRead> {
    const res = await ServerClient.get<IVehicleAdminRead>('vehicles', {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async approve(vehicleId: number): Promise<any> {
    const res = await ServerClient.post<any>(`/vehicles/${vehicleId}/approve`);
    return res?.data;
  }

  async reject(vehicleId: number, reason: string): Promise<any> {
    const res = await ServerClient.post<any>(`/vehicles/${vehicleId}/reject`, { reason });
    return res?.data;
  }
}

export const vehicleService = new VehicleService();
export default vehicleService;
