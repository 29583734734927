import React, { useEffect, useMemo } from 'react';
import { TableContextProvider, useTableControls } from '../../../../common/components/table/table-context';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import eiqConnectStore from '../../store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { IResidentAddresses, IVehicleRead } from '../../../../common/services/residents-service/residents.service.types';
import { VehiclesList } from './vehicles-list/vehicles-list';
import { vehiclesListConfig } from './vehicles.config';
import { SelectAll } from 'features/eiq-connect/constants/common.constant';
import { VehicleAddEdit } from './vehicle-add-edit/vehicle-add-edit';
import { VehicleDetails } from './vehicle-details/vehicle-details';
import { VehicleShare } from './vehicle-share/vehicle-share';
import configurationStore from 'common/stores/configuration/configuration.store';

const Vehicles = observer(() => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const defaultConfiguration = configurationStore.configuration?.default;

  const data = useMemo(() => {
    const selectedAddresses = eiqConnectStore.selectedAddressesId;
    const isAll = !selectedAddresses || selectedAddresses?.length === 0 || selectedAddresses?.[0] === SelectAll;

    if (isAll) {
      return eiqConnectStore.residentDetails?.addresses?.reduce(
        (acc, curr) =>
          acc.concat(
            curr.vehicles.map((vehicle) => {
              return {
                ...vehicle,
                address: curr.address,
              };
            }) as any,
          ),
        [],
      );
    } else if (selectedAddresses?.length > 0) {
      const vehicles: IVehicleRead[] = [];

      selectedAddresses?.forEach((value) => {
        const index = value as number;

        if (!eiqConnectStore.residentDetails?.addresses[index]) {
          return;
        }

        const currentAddress: IResidentAddresses = { ...eiqConnectStore.residentDetails?.addresses[index] };
        const vehicle = currentAddress.vehicles.map((vehicle) => {
          return {
            ...vehicle,
            address: currentAddress.address,
          };
        });
        vehicles.push(...vehicle);
      });

      return vehicles;
    }

    return [];
  }, [eiqConnectStore.residentDetails, eiqConnectStore.selectedAddressesId]);

  const tableControls = useTableControls({
    onSelectedRow: (row: any) => {
      if (isMobile) {
        navigate(`/eiq-connect/vehicles/${row?.id}/view`);
      }
    },
    clientSide: true,
    data: data,
    initialColumns: vehiclesListConfig,
  });

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        action: false,
        arrow: true,
        isDeactivated: false,
      });
    } else {
      tableControls.hideColumns({
        action: true,
        arrow: false,
        isDeactivated: true,
      });
    }
  }, [isMobile]);

  const location = useLocation();
  const isBasePath = location.pathname.endsWith('/vehicles');
  const isSearchPath = location.pathname.endsWith('/vehicles/search');

  return (
    <TableContextProvider controls={tableControls}>
      {(isBasePath || isSearchPath) && <VehiclesList className={isBasePath || isSearchPath ? null : 'eiq-display-none'} />}

      <Routes>
        <Route path="add" element={<VehicleAddEdit pageType="ADD" />} />
        <Route path="/:id/edit" element={<VehicleAddEdit pageType="EDIT" />} />
        <Route path="/:id/view" element={<VehicleDetails />} />
        {!defaultConfiguration?.isShareVehicleConnect && <Route path="share" element={<VehicleShare />} />}
      </Routes>
    </TableContextProvider>
  );
});

export default Vehicles;
