export enum FeatureName {
  FastAccess = 'FastAccess',
  SmsNotifications = 'SmsNotifications',
  CA3000 = 'CA3000',
  CA4K = 'CA4K',
  TrafficLogix = 'TrafficLogix',
  CA3000Expirations = 'CA3000Expirations',
  NorthStar = 'NorthStar',
  ConnectEditPin = 'ConnectEditPin',
}

export const featureNameMap: Record<FeatureName, string> = {
  [FeatureName.FastAccess]: 'Fast access',
  [FeatureName.SmsNotifications]: 'SMS notifications',
  [FeatureName.CA3000]: 'CA3000',
  [FeatureName.CA4K]: 'CA4K',
  [FeatureName.TrafficLogix]: 'Traffic Logix',
  [FeatureName.CA3000Expirations]: 'CA3000 Expirations',
  [FeatureName.NorthStar]: 'North Star',
  [FeatureName.ConnectEditPin]: 'Allow resident edit PIN in EIQ Connect',
};
