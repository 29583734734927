import React from 'react';
import './eiq-connect-account.scss';
import { Route, Routes, useLocation } from 'react-router';
import { EditAccount } from './eiq-connect-account-edit/eiq-connect-account-edit';
import { AccountInfo } from './eiq-connect-account';
import { observer } from 'mobx-react-lite';

export const Account = observer(() => {
  const location = useLocation();
  const isBasePath = location.pathname.endsWith('/account');

  return (
    <>
      {isBasePath && <AccountInfo className={isBasePath ? null : 'eiq-display-none'} />}
      <Routes>{<Route path="/edit" element={<EditAccount />} />}</Routes>
    </>
  );
});
