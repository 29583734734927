import { ServerClient } from 'common/utils/http/http-clients';
import { ICreatePet, IUpdatePet } from './pet-service.types';
import { IPetRead } from '../residents-service/residents.service.types';

class PetService {
  async createPet(residentId: number, addressId: number, data: ICreatePet): Promise<IPetRead> {
    const res = await ServerClient.post<IPetRead>(`/residents/${residentId}/addresses/${addressId}/pets`, data);
    return res?.data;
  }

  async updatePet(residentId: number, addressId: number, petId: number, data: IUpdatePet): Promise<IPetRead> {
    const res = await ServerClient.patch<IPetRead>(`/residents/${residentId}/addresses/${addressId}/pets/${petId}`, data);
    return res?.data;
  }

  async deletePet(residentId: number, addressId: number, petId: number): Promise<void> {
    const res = await ServerClient.delete<void>(`/residents/${residentId}/addresses/${addressId}/pets/${petId}`);
    return res?.data;
  }
}

export const petService = new PetService();
export default petService;
