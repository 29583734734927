import { ServerClient } from 'common/utils/http/http-clients';
import { IUploadPhoto } from './file.service.types';

class FileService {
  async uploadPhoto(file: Blob): Promise<IUploadPhoto> {
    const data = new FormData();
    data.append('file', file);
    const res = await ServerClient.post<IUploadPhoto>('/files/images', data);
    return res?.data;
  }
}

export const accountService = new FileService();
export default accountService;
