import { ServerClient } from 'common/utils/http/http-clients';
import { ISignUpResident } from './sign-up.types';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';

class WGSignUpService {
  async verify(login: string, pin: string): Promise<any> {
    await communityResolverService.resolve(login);
    const res = await ServerClient.post<any>('/residents/verify', { login, pin });
    return res?.data;
  }

  async signUp(body: ISignUpResident): Promise<any> {
    const res = await ServerClient.post<any>('/residents/sign-up', body);
    return res?.data;
  }
}

export const wgSignUpService = new WGSignUpService();
export default wgSignUpService;
