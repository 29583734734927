import { ICreateVehicle, IUpdateVehicle } from 'common/services/vehicle-service/vehicle.service.types';
import { Dayjs } from 'dayjs';
import { IVehicleFormData } from './vehicle-add-edit.types';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';

const toStringDate = (date: Dayjs | null | '') => (date === '' || date === null ? null : date?.format('YYYY-MM-DD'));

export const toCreateVehicleModel = (values: IVehicleFormData): ICreateVehicle => {
  return {
    year: values.year!,
    make: values.make!,
    model: values.model!,
    licenseNumber: values.licenseNumber!,
    licenseState: values.licenseState!,
    color: values.color!,
    owner: values.owner!,
    registrationAddress: values.registrationAddress,
    facilityCode: null,
    rentStartDate: toStringDate(values.rentStartDate),
    rentEndDate: toStringDate(values.rentEndDate),
    deviceId: null,
    parkSpaceNumber: null,
    status: null,
    additionalInformation: null,
    photoUrl: values.photoUrl,
    isNoTransponder: values.isNoTransponder,
  };
};

// TODO [SECUIRTY] We must not pass fields that are not available in web guest. It should be fixed once once https://bilsoft.atlassian.net/browse/CCS-465 is done
export const toUpdateVehicleModel = (values: IVehicleFormData, vehicle: IVehicleRead): IUpdateVehicle => {
  return {
    id: vehicle.id,
    year: values.year!,
    make: values.make!,
    model: values.model!,
    licenseNumber: values.licenseNumber!,
    licenseState: values.licenseState!,
    color: values.color!,
    owner: values.owner!,
    registrationAddress: values.registrationAddress,
    facilityCode: null,
    rentStartDate: toStringDate(values.rentStartDate),
    rentEndDate: toStringDate(values.rentEndDate),
    deviceId: vehicle.deviceId,
    parkSpaceNumber: vehicle.parkSpaceNumber,
    status: null, // Resident status is not available in web guest
    additionalInformation: vehicle.additionalInformation,
    expirationDate: null,
    photoUrl: values.photoUrl,
    isNoTransponder: values.isNoTransponder,
  };
};
