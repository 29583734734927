import React from 'react';
import { IEiqDetailsRow } from './eiq-details.types';

export const EiqDetailsRow = ({ title, value, className }: IEiqDetailsRow) => {
  return (
    <div className={`eiq-details-row ${className ? className : ''}`}>
      <div className="eiq-details-row__title">{title}</div>
      <div className="eiq-details-row__text">{value}</div>
    </div>
  );
};
