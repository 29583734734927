import { ServerClient } from 'common/utils/http/http-clients';
import { IRegisterPushDevice } from './push-notification.service.types';

class PushNotificationService {
  async registerDevice(residentId: number, data: IRegisterPushDevice) {
    const res = await ServerClient.post(`/residents/${residentId}/notifications/push/registrations`, data);
    return res?.data;
  }

  async updateDevice(residentId: number, registrationId: string, data: IRegisterPushDevice) {
    const res = await ServerClient.put(`/residents/${residentId}/notifications/push/registrations/${registrationId}`, data);
    return res?.data;
  }
}

export const pushNotificationService = new PushNotificationService();
export default pushNotificationService;
