import { FormInstance } from 'antd';
import { RuleType } from 'rc-field-form/lib/interface';
import { phoneValidationRule } from 'common/validation/phone-number-rule';
import { ValidationMessages } from 'common/validation/validation-messages';

export const getWebGuestAccountEditValidation = (form: FormInstance) => {
  const validateConfirmation = (_: any, value: string) => {
    const currentEmail = form.getFieldValue('email');
    if (currentEmail !== value) {
      return Promise.reject(new Error("The entered emails don't match"));
    }
    return Promise.resolve();
  };

  const validateConfirmationPin = (_: any, value: string) => {
    const newPin = form.getFieldValue('newPin');
    if (!newPin && !value) {
      return Promise.resolve();
    }
    if (newPin !== value) {
      return Promise.reject(new Error("The entered pin codes don't match"));
    }
    return Promise.resolve();
  };

  return {
    confirmPin: [{ validator: validateConfirmationPin }],
    email: [
      { required: true, message: ValidationMessages.Email.RequiredMessage },
      { message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType },
    ],
    confirmEmail: [
      { required: true, message: ValidationMessages.Email.ConfirmMessage },
      { message: ValidationMessages.Email.NotValidMessage, type: 'email' as RuleType },
      { validator: validateConfirmation },
    ],
    phone: [phoneValidationRule, { required: true, message: 'Please enter a phone number' }],
    vacationStartDate: [{ required: true, message: 'Vacation start date is required!' }],
    vacationEndDate: [
      { required: true, message: 'Vacation end date is required!' },
      ({ getFieldValue }: any) => ({
        validator: (_: any, value: string) => {
          const startDate = getFieldValue('vacationStartDate');
          if (value && value < startDate) {
            return Promise.reject(new Error('Vacation end date must be greater than start date'));
          }
          return Promise.resolve();
        },
      }),
    ],
  };
};
