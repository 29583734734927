import { DetailRawView } from 'features/eiq-connect/components/detail-raw-view/detail-raw-view';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { IAccessCardRead } from 'common/services/residents-service/residents.service.types';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import TopHeader from '../../components/top-header/top-header';
import BottomHeader from '../../components/bottom-header/bottom-header';
import { DateToFormat } from 'common/helpers/date.helper';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { Tag } from 'antd';
import featureStore from 'common/stores/features/feature.store';

export const AccessCardDetails = observer(() => {
  const { id } = useParams();
  const [accessCard, setAccessCard] = useState<IAccessCardRead>();

  useEffect(() => {
    if (!eiqConnectStore.residentDetails || !id) {
      return;
    }

    const accessCard = eiqConnectStore.residentDetails.addresses.flatMap((item) => item.accessCards).find((item) => item.id === Number(id));
    setAccessCard(accessCard);
  }, [eiqConnectStore.residentDetails]);

  const formatAddress = useMemo(() => {
    if (!accessCard) {
      return '-';
    }

    const address = eiqConnectStore.residentDetails?.addresses.find((item) => item.accessCards.includes(accessCard))?.address;
    return `${address?.streetNumber} ${address?.street.name}`;
  }, [accessCard]);

  const getValidPeriod = () => {
    if (accessCard?.validTo && accessCard?.validFrom) {
      return `${DateToFormat(accessCard?.validFrom, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(
        accessCard?.validTo,
        DATE_SHORT_MMM_DD_YYYY,
      )}`;
    } else {
      return '-';
    }
  };

  return (
    <div className="eiq-guests-page">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Details" />
      </div>

      <div className="eiq-content">
        <div className="w-100">
          <DetailRawView title="Address" value={formatAddress} />
          <DetailRawView title="Owner" value={accessCard?.owner || '-'} />
          <DetailRawView title="Facility code" value={accessCard?.facilityCode} />
          <DetailRawView title="Device id" value={accessCard?.deviceId} />
          <DetailRawView title="Description" value={accessCard?.additionalInformation || '-'} />
          <DetailRawView title="Registration address" value={accessCard?.registrationAddress || '-'} />
          {featureStore.isCA3000ExpirationsEnabled && (
            <div className="eiq-details-row">
              <div className="eiq-details-row__title">Validity period</div>
              <div className="eiq-details-row__text">{getValidPeriod()}</div>
            </div>
          )}
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Status</div>
            {accessCard?.isDeactivated && (
              <Tag className="eiq-tag" color="#FFA39E" style={{ color: '#292B2E' }}>
                Deactivated
              </Tag>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
