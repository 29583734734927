import { KEEP_ME_LOGGED_IN } from 'common/stores/user-auth/user-auth.constants';

const getKeepMeLoggedIn = () => {
  let result = localStorage.getItem(KEEP_ME_LOGGED_IN);
  if (result) {
    return JSON.parse(result);
  }
  return false;
};

export const getItem = (name: string) => {
  if (getKeepMeLoggedIn()) {
    return localStorage.getItem(name);
  } else {
    return sessionStorage.getItem(name);
  }
};

export const setItem = (name: string, value: string) => {
  if (getKeepMeLoggedIn()) {
    return localStorage.setItem(name, value);
  } else {
    return sessionStorage.setItem(name, value);
  }
};

export const removeItem = (name: string) => {
  if (getKeepMeLoggedIn()) {
    return localStorage.removeItem(name);
  } else {
    return sessionStorage.removeItem(name);
  }
};

export const clear = () => {
  if (getKeepMeLoggedIn()) {
    return localStorage.clear();
  } else {
    return sessionStorage.clear();
  }
};
