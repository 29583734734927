import { isEmail } from './email.helper';
import { isPhoneNumber } from './phone.helper';

export const getLogin = (input?: string | null) => {
  const login = input?.trim();

  if (!login) {
    return '';
  }

  if (isEmail(login)) {
    return login;
  }

  const phone = login.replace(/\D/g, '');
  if (isPhoneNumber(phone)) {
    return phone;
  }

  return login;
};
