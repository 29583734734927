import Axios from 'axios';

const CommunityResolverClient = Axios.create({
  baseURL: process.env.REACT_APP_COMMUNITY_RESOLVER_API_URL,
});

CommunityResolverClient.interceptors.request.use((config) => {
  config.headers['X-API-KEY'] = process.env.REACT_APP_COMMUNITY_RESOLVER_API_KEY;
  return config;
});

export default CommunityResolverClient;
