export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET_ERROR = 'LOGIN_RESET_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_USER = 'SET_USER';

export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';
export const USER_FULL_NAME = 'USER_FULL_NAME';

export const SignInEndpoint = '/api/Authorization/signin';
export const RefreshTokenEndpoint = '/api/Authorization/RefreshToken';
export const EndSessionEndpoint = '/connect/revocation';

export const IS_USER_PREVIOUSLY_WAS_LOGGED = 'IS_USER_PREVIOUSLY_WAS_LOGGED';
export const KEEP_ME_LOGGED_IN = 'KEEP_ME_LOGGED_IN';
