import LogoIcon from '../../../../assets/icons/eiq-connect/eiq-connect-logo.png';
import React, { ReactNode } from 'react';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';

interface TopHeaderProps {
  children?: ReactNode;
}

const TopHeader: React.FC<TopHeaderProps> = ({ children }) => {
  const { isMobileOrTable } = useMedia();

  return (
    <>
      {isMobileOrTable && (
        <div className="eiq-top-header">
          <img className="eiq-logo" src={LogoIcon} alt="logo" />
          {children}
        </div>
      )}
    </>
  );
};

export default TopHeader;
