import { ServerClient } from 'common/utils/http/http-clients';
import { IFeature } from './feature-service.types';

class FeatureService {
  async getFeatures(): Promise<IFeature[]> {
    const res = await ServerClient.get<IFeature[]>('/features');
    return res?.data;
  }
}

export const featureService = new FeatureService();
export default featureService;
