import { ICitationRead } from 'common/services/citation-service/citation-service.types';

export const getCitationVisitorName = (citation?: ICitationRead) => {
  if (!citation) {
    return '';
  }
  if (citation.companyName) {
    return citation.companyName;
  }
  if (citation.guestName) {
    return citation.guestName;
  }
  return citation.residentName;
};
