import { ServerClient } from 'common/utils/http/http-clients';
import { IAuthResponse, IAuthRefreshTokenResponse } from './login.types';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';

class WGLoginService {
  async authenticate(login: string, password: string, captchaToken: string): Promise<IAuthResponse> {
    await communityResolverService.resolve(login);
    const res = await ServerClient.post<IAuthResponse>('/auth/resident/authenticate', { login, password, captchaToken });
    return res?.data;
  }

  async refreshToken(refreshToken: string): Promise<IAuthRefreshTokenResponse> {
    const res = await ServerClient.post<IAuthRefreshTokenResponse>('/auth/refresh-token', { refreshToken });
    return res.data;
  }

  async resetPassword(login: string): Promise<any> {
    await communityResolverService.resolve(login);
    const res = await ServerClient.post<any>('/residents/reset-password', { login });
    return res?.data;
  }

  async confirmResetPassword(login: string, code: string, password: string): Promise<any> {
    const res = await ServerClient.post<any>('/residents/reset-password/confirm', { login, code, password });
    return res?.data;
  }
}

export const wgLoginService = new WGLoginService();
export default wgLoginService;
