import configurationService from 'common/services/configuration-service/configuration.service';
import { ISystemConfigurationRead } from 'common/services/configuration-service/configuration.service.types';
import { makeAutoObservable } from 'mobx';
import { SYSTEM_CONFIGURATION } from './configuration.constants';
import React from 'react';
import { getItem, removeItem, setItem } from 'common/helpers/authorization-store.helper';

class ConfigurationStore {
  configuration: ISystemConfigurationRead | null = null;

  constructor() {
    makeAutoObservable(this);
    this.restoreConfiguration();
  }

  setConfiguration(configuration: ISystemConfigurationRead | null) {
    setItem(SYSTEM_CONFIGURATION, JSON.stringify(configuration));
    this.configuration = configuration;
  }

  restoreConfiguration() {
    const configurationSource = getItem(SYSTEM_CONFIGURATION);
    this.configuration = configurationSource ? JSON.parse(configurationSource) : null;
  }

  clearConfiguration() {
    removeItem(SYSTEM_CONFIGURATION);
    this.configuration = null;
  }

  async requestConfiguration() {
    const configuration = await configurationService.getSystemConfiguration();
    this.setConfiguration(configuration);
  }
}

const configurationStore = new ConfigurationStore();
export const ConfigurationStoreContext = React.createContext<ConfigurationStore>(configurationStore);
export default configurationStore;
