import React from 'react';
import { Tag } from 'antd';
import { IAddressOccupant } from 'common/services/guest-service/guest.service.types';
import { ICellRendererProps } from 'common/components/table/table.types';
import { ownershipTypeStatusColorMap } from 'common/constans/common.constants';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';

export const webGuestShareConfig = [
  {
    key: 'name',
    title: 'Name',
    dataKey: 'name',
    width: 180,
    minWidth: 145,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<IAddressOccupant>) => {
      if (!rowData) {
        return <div></div>;
      }

      const residentAddressesIds = eiqConnectStore.residentDetails?.addresses.map((i) => i.id);
      const isShared = rowData.sharedResidentAddressData?.some(
        (i) => residentAddressesIds?.includes(i.sharedResidentAddressId) && i.isGuestShared,
      );

      return (
        <div className="eiq-column">
          <div className="eiq-column__title">{rowData.name}</div>
          <div className="eiq-column__text">{`${rowData.address?.streetNumber} ${rowData.address?.street?.name}`}</div>

          <div className="eiq-column-row">
            <Tag className="eiq-tag" color={guestListOccupantsMap[isShared + '']}>
              {isShared ? 'Shared' : 'Not shared'}
            </Tag>
          </div>
        </div>
      );
    },
  },
  {
    key: 'ownershipType',
    title: 'OwnershipType',
    dataKey: 'ownershipType',
    width: 180,
    minWidth: 130,
    resizable: true,
    sortable: true,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<IAddressOccupant>) => {
      return (
        <Tag className="eiq-tag" color={ownershipTypeStatusColorMap[rowData.ownershipType]} style={{ color: '#292B2E' }}>
          {rowData.ownershipType}
        </Tag>
      );
    },
  },
  {
    key: 'share',
    title: 'Share',
    width: 60,
    minWidth: 40,
  },
];

const guestListOccupantsMap: Record<string, string> = {
  true: '#EECAFF',
  false: '#D1E3FF',
};
