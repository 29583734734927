import { Button, Space, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTableContext } from '../../../../../common/components/table/table-context';
import { ReactComponent as EditIcon } from 'assets/icons/eiq-connect/edit.svg';
import { IEIQConnectRead } from '../guests.config';
import eiqConnectStore from '../../../store/eiq-connect.store';
import { mapToGuestValidity } from '../../../../../common/helpers/guest-validity.helper';
import { getDateRangePeriod, StrToDateTimeLocal } from '../../../../../common/helpers/date.helper';
import { getGuestTypeTag } from '../../../../../common/components/expired-guest-tag/expired-guest-tag';
import { DetailRawView } from 'features/eiq-connect/components/detail-raw-view/detail-raw-view';
import { isServiceGuest } from 'common/helpers/guest-type.helpers';
import { FeatureName } from 'common/enums/feature-name.enum';
import Feature from 'common/components/feature/feature';
import { ReactComponent as QRCodeIcon } from 'assets/icons/eiq-connect/qr-code.svg';
import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';

export const EiqConnectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isMobileOrTable } = useMedia();
  const tableContext = useTableContext();
  const selectedRow = tableContext.getSelectedRow();
  const [initialValues, setInitialValues] = useState<IEIQConnectRead>();

  useEffect(() => {
    if (selectedRow) {
      setInitialValues(selectedRow);
    } else {
      eiqConnectStore.residentDetails?.addresses.forEach((address) => {
        address.guests.forEach((guest) => {
          if (guest.id == +id!) {
            tableContext.setSelectedRow({
              ...guest,
              validity: mapToGuestValidity(guest.validity, StrToDateTimeLocal(guest?.visitDate)),
              address: `${address.address.streetNumber} ${address.address.street.name} `,
            });
          }
        });
      });
    }
  }, [tableContext.getSelectedRow(), eiqConnectStore.residentDetails]);

  const getVisitingDays = useMemo(() => {
    return initialValues?.daysOfWeek.length! >= 7 ? `All days` : initialValues?.daysOfWeek?.join(', ');
  }, [initialValues]);

  return (
    <div className="eiq-guests-page">
      <div className="eiq-header">
        <TopHeader>
          <Space size={10} className="eiq-action">
            <Feature name={FeatureName.FastAccess}>
              <Button
                size="large"
                className="round-primary"
                icon={<QRCodeIcon />}
                onClick={() => navigate(`/eiq-connect/guests/${id}/eiq-passport`)}
              />
            </Feature>
            <Button size="large" className="round-primary" onClick={() => navigate(`/eiq-connect/guests/${id}/edit`)} icon={<EditIcon />} />
          </Space>
        </TopHeader>
        <BottomHeader title="Details" />

        {!isMobileOrTable && (
          <Space size={10} className="eiq-action">
            <Feature name={FeatureName.FastAccess}>
              <Button
                size="large"
                className="round-primary"
                icon={<QRCodeIcon />}
                onClick={() => {
                  navigate(`/eiq-connect/guests/${id}/eiq-passport`);
                }}
              />
            </Feature>
            <Button size="large" className="round-primary" onClick={() => navigate(`/eiq-connect/guests/${id}/edit`)} icon={<EditIcon />} />
          </Space>
        )}
      </div>

      <div className="eiq-content">
        <div className="w-100">
          <DetailRawView title="Address" value={initialValues?.address} />
          <DetailRawView title="First name" value={initialValues?.firstName} />
          <DetailRawView title="Last name" value={initialValues?.lastName} />
          {isServiceGuest(initialValues?.type) && <DetailRawView title="Company name" value={initialValues?.companyName} />}
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Type</div>
            <div className="eiq-column-row">
              {initialValues && getGuestTypeTag(initialValues)}
              {initialValues?.isShared ? <Tag className="eiq-tag expired">Shared</Tag> : null}
            </div>
            {}
          </div>
          <DetailRawView title="Validity" value={initialValues && getDateRangePeriod(initialValues)} />
          {initialValues?.daysOfWeek?.length !== 0 && <DetailRawView title="Days allowed to visit" value={getVisitingDays} />}
          <DetailRawView title="License" value={initialValues?.carLicensePlates.find((i) => i.isPrimary)?.number} />
          <DetailRawView title="License state" value={initialValues?.carLicensePlates.find((i) => i.isPrimary)?.state} />
        </div>
      </div>
    </div>
  );
};
