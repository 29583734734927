import userAuthStore from '../../../../common/stores/user-auth/user-auth.store';
import { Button, Divider, Dropdown, MenuProps, Space } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { ReactComponent as LogOut } from 'assets/icons/eiq-connect/logout.svg';
import { ReactComponent as Edit } from 'assets/icons/eiq-connect/edit.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/eiq-connect/phone.svg';
import { ReactComponent as LogOutWhite } from 'assets/icons/eiq-connect/logout-white.svg';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import './eiq-connect-account.scss';
import InfoField from 'common/components/info-block/info-field/info-field';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import ConfirmationModal from 'features/eiq-connect/components/confirmation-modal/confirmation-modal';
import { formatPhone } from '../../../../common/helpers/phone.helper';
import dayjs from 'dayjs';
import Feature from 'common/components/feature/feature';
import { FeatureName } from 'common/enums/feature-name.enum';
import { AccountFastAccessBlock } from './components/account-qr-code';
import { EllipsisOutlined } from '@ant-design/icons';
import residentsService from 'common/services/residents-service/residents.service';
import { getPhoneComparer } from 'features/eiq-connect/helpers/phone.helper';
import { useLocation } from 'react-router-dom';
import TopHeader from '../../components/top-header/top-header';
import configurationStore from 'common/stores/configuration/configuration.store';

const VerticalEllipsis = () => <EllipsisOutlined style={{ color: '#505762', transform: 'rotate(90deg)' }} />;

export const AccountInfo = observer(({ className }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMainPath = location.pathname.endsWith('/account');
  const [accountInfo, setAccountInfo] = useState(eiqConnectStore.residentDetails);
  const isShowPin = configurationStore.configuration?.default.isShowPinConnect;

  useEffect(() => {
    if (eiqConnectStore.residentDetails?.id) {
      setAccountInfo(eiqConnectStore?.residentDetails);
    }
  }, [eiqConnectStore?.residentDetails]);

  const GetVacationField = (startDateString: string | undefined, endDateString: string | undefined): ReactNode => {
    const isVacation = startDateString && endDateString && dayjs().isBetween(startDateString, endDateString, 'day', '[]');

    if (!isVacation) {
      return <InfoField value={'Home'} label="Vacation" valueClassName="eiq-label primary" />;
    } else {
      return <InfoField value={'Vacationing'} label="Vacation" valueClassName="eiq-label warning" />;
    }
  };

  const logOutModal = useModal({});
  const deleteAccountModal = useModal({});

  const logOut = () => {
    userAuthStore.logOut();
  };

  const handleClickExtraMenu = (e: any) => {
    if (e.key === 'Privacy') {
      window.open('https://www.entranceiq.net/privacy-policy.html', '_blank');
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'Privacy',
      label: 'Privacy Policy',
      // icon: <EllipsisOutlined />,
      onClick: handleClickExtraMenu,
    },
  ];

  const onDeleteAccountClick = () => {
    residentsService.requestDeletion(userAuthStore.webGuestResidentId!).then((result) => {
      logOut();
    });
  };

  return (
    <div className={`eiq-guests-page ${className ?? ''}`}>
      <div className={`eiq-header ${isMainPath && 'main-page'}`}>
        <h1 className="eiq-title">Account</h1>
        <div className="eiq-top-header">
          <TopHeader />
          <Space size={10} className="eiq-action">
            <Button size="large" className="round-primary" icon={<LogOut />} onClick={logOutModal.showModal} />
            <Button
              size="large"
              className="round-primary"
              icon={<Edit />}
              onClick={() => {
                navigate('/eiq-connect/account/edit');
              }}
            />
            <Dropdown rootClassName="android-photo-menu" menu={{ items }} arrow={false}>
              <Button size="large" className="round-primary" icon={<VerticalEllipsis />} />
            </Dropdown>
          </Space>
        </div>
      </div>
      <div className="eiq-content">
        <div className=" eiq-account">
          <div className="eiq-account-user-info-container">
            <p className="eiq-user-name">{`${accountInfo?.firstName} ${accountInfo?.lastName}`}</p>
            <div className="eiq-community">
              <InfoField label="Community" value={accountInfo?.addresses?.[0]?.address?.street?.community?.name} />
            </div>
            <Feature name={FeatureName.FastAccess}>
              <AccountFastAccessBlock />
            </Feature>
          </div>

          <div className="eiq-account-user-info-container">
            <div className="eiq-account-info-container">
              <div className="eiq-account-phones">
                <span>Phone numbers</span>
                {accountInfo?.phones
                  .slice()
                  .sort(getPhoneComparer())
                  .map((item, index) => (
                    <div className="contact-phone" key={`contact-phone-${index}`}>
                      <PhoneIcon />
                      <span>{formatPhone(item.number)}</span>
                      <span className={`eiq-label ${item.label?.toLowerCase()}`}>{item.label}</span>
                    </div>
                  ))}
              </div>
              <div className="eiq-account-info-block">
                <Divider className="account-divider" />
                <InfoField label="Email" value={accountInfo?.email} valueIcon={<Email />} />
                <Divider className="account-divider" />
                {isShowPin && (
                  <>
                    <InfoField label="PIN" value={accountInfo?.pin} />
                    <Divider className="account-divider" />
                  </>
                )}
                <div className="eiq-account-vacation">{GetVacationField(accountInfo?.vacationStartDate, accountInfo?.vacationEndDate)}</div>
                <Divider className="account-divider" />

                <div className="eiq-account-notification">
                  <InfoField label="Notification" value={''} />
                  {accountInfo?.isSMSNotificationEnabled ? (
                    <InfoField label="" value={'Receive SMS notifications'} />
                  ) : accountInfo?.isEmailNotificationEnabled ? (
                    <InfoField label="" value={'Receive email notifications'} />
                  ) : accountInfo?.isPushNotificationEnabled ? (
                    <InfoField label="" value={'Receive push notifications'} />
                  ) : (
                    <InfoField label="" value={'Do not receive any notifications'} />
                  )}
                </div>

                <Divider className="account-divider" />

                <div className="delete-account">
                  <span onClick={deleteAccountModal.showModal}>Delete my account</span>
                </div>

                <Divider className="account-divider" />
                {/* <div className="eiq-account-notification">
              <InfoField label="Font size" value={16} />
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Log out"
        isOpen={logOutModal?.isOpenModal}
        onClose={logOutModal?.hideModal}
        mainText={'Are you sure you want to log out?'}
        handleConfirm={logOut}
        confirmBtnText={'Log out'}
        confirmBtnIcon={<LogOutWhite />}
      />
      <ConfirmationModal
        title="Delete account"
        isOpen={deleteAccountModal?.isOpenModal}
        onClose={deleteAccountModal?.hideModal}
        mainText="Deleting your account is not reversible. To restore your account, you will need to contact the community administrator. Are you sure you want to delete it?"
        handleConfirm={onDeleteAccountClick}
        confirmBtnText="Ok"
        confirmBtnIcon={null}
      />
    </div>
  );
});
