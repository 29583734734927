import React, { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { HotkeyInputProps } from './hotkey-input.types';
import { Input, InputRef } from 'antd';
import { Key } from '../key/key';
import './hotkey-input.scss';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

/*
All methods:
  https://www.npmjs.com/package/rc-input
  https://ant.design/components/input
*/
export const HotkeyInput = ({
  hotkey = '',
  className,
  keyLabel,
  label,
  onPressEnter,
  onSearch,
  placeholder,
  suffix,
  ...rest
}: HotkeyInputProps) => {
  const inputRef = useRef<InputRef>(null);
  const handleClick = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.preventDefault();
    inputRef?.current?.focus();
  };

  useHotkeys(hotkey, handleClick);

  const handleOnSearch = () => {
    console.log('search', inputRef?.current?.input?.value);
    if (onSearch) {
      onSearch(inputRef?.current?.input?.value?.trim() ?? '');
    }
  };

  // On Clear case
  const handleOnInput = (value: any) => {
    if (value.target.value?.length === 0) {
      if (onSearch) {
        onSearch(value.target.value.trim() ?? '');
      }
    }
  };

  return (
    <div className={`hotkey-input ${className ?? ''}`}>
      <Input
        ref={inputRef}
        placeholder={placeholder}
        onPressEnter={(event) => {
          handleOnSearch();
          if (onPressEnter) {
            onPressEnter(event);
          }
        }}
        onChange={handleOnInput}
        prefix={<SearchIcon onClick={handleOnSearch} />}
        suffix={
          <>
            {suffix}
            {keyLabel && <Key className={'hotkey-input__key'} value={keyLabel} />}
          </>
        }
        size="middle"
        {...rest}
      />
    </div>
  );
};
