import React from 'react';
import { Modal, Space, Button, notification } from 'antd';
import './confirmation-modal.scss';
import { IConfirmationModalProps } from './confirmation-modal.types';

const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  mainText,
  handleConfirm,
  confirmBtnText,
  confirmBtnIcon,
}: IConfirmationModalProps) => {
  const hideModal = (...rest: any) => {
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };
  return (
    <Modal centered title={title} width={350} open={isOpen} wrapClassName="custom-modal" className="confirmation-modal" closable={false}>
      <Space className="full-width" size={13}>
        <pre style={{ whiteSpace: 'normal' }}>
          <p>{mainText}</p>
        </pre>
      </Space>

      <Space direction="vertical" className="full-width btn-container">
        <Button
          type="primary"
          style={{ width: '320px' }}
          icon={confirmBtnIcon}
          onClick={() => {
            handleConfirm();
          }}>
          {confirmBtnText}
        </Button>

        <Button key="cancel" type="link" onClick={() => hideModal()} style={{ width: '320px' }}>
          Cancel
        </Button>
      </Space>
    </Modal>
  );
};

export default ConfirmationModal;
