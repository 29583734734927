import '../../vehicles/vehicle-add-edit/vehicle-add-edit.scss';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { observer } from 'mobx-react-lite';
import { Button, DatePicker, Divider, Form, Input, Select, SelectProps, Space } from 'antd';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { AddEditPageHeader } from 'features/eiq-connect/components/add-edit-page-header/add-edit-page-header';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import residentsService from 'common/services/residents-service/residents.service';
import { AddEditActions } from 'features/eiq-connect/components/add-edit-actions/add-edit-actions';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { notification } from 'common/utils/notification';
import { IPetRead } from 'common/services/residents-service/residents.service.types';
import { StrToDate } from 'common/helpers/date.helper';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { Upload, Modal } from 'antd';
import type { UploadProps } from 'antd';
import FileService from '../../../../../common/services/file-service/file.service';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { MAX_FILE_SIZE_BYTES } from '../../../../../common/constans/common.constants';
import { getBase64 } from '../../../../../common/helpers/getBase64.helper';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { CameraOutlined, FileImageOutlined } from '@ant-design/icons';
import { IAddEditPetProps, IPetFormData } from './pet-add-edit.types';
import petService from 'common/services/pet-service/pet-service';
import { toCreatePetModel, toUpdatePetModel } from './pet-add-edit.helpers';
import { addEditPetValidation } from 'common/validation/pet.validation';
import PetDeleteModal from '../pet-delete-modal/pet-delete-modal';
import { IConfirmDeletePetProps } from '../pet-delete-modal/pet-delete-modal.types';
import { ResidentTypesEnum } from 'common/enums/resident-types.enum';

const { Dragger } = Upload;

const initData: IPetFormData = {
  name: null,
  type: null,
  breed: null,
  color: null,
  dateOfBirth: null,
  tag: null,
  tagExpirationDate: null,
  chipNumber: null,
  addressId: null,
  photoUrl: null,
};

export const PetAddEdit = observer(({ pageType }: IAddEditPetProps) => {
  const [form] = Form.useForm();
  const { isMobileOrTable } = useMedia();
  const { id } = useParams();
  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [addressOptions, setAddressOptions] = useState<SelectProps['options']>([]);
  const [currentPet, setCurrentPet] = useState<IPetRead>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const isFormSubmittedSuccessful = useRef(false);

  useEffect(() => {
    return () => {
      if (!isFormSubmittedSuccessful.current) {
        notification.destroy();
      }
    };
  }, []);

  const getAddresses = useCallback(() => {
    const addresses = eiqConnectStore?.residentDetails?.addresses || [];

    return addresses.filter((address) => address.ownershipType !== ResidentTypesEnum.Developer);
  }, []);

  useEffect(() => {
    if (!eiqConnectStore.residentDetails) {
      return;
    }

    const addressOptions = getAddresses().map((item) => ({
      value: item.address.id,
      label: formatAddressLabel(item.address),
    }));

    setAddressOptions(addressOptions || []);

    if (pageType === 'EDIT') {
      const pet = eiqConnectStore.residentDetails.addresses.flatMap((item) => item.pets).find((item) => item.id === Number(id!));
      setCurrentPet(pet);
    }
  }, [eiqConnectStore.residentDetails, getAddresses]);

  useEffect(() => {
    if (!form || !currentPet) {
      return;
    }

    const addressId = eiqConnectStore.residentDetails?.addresses?.find((item) => item.pets.includes(currentPet))?.address.id;

    const formData: IPetFormData = {
      name: currentPet.name,
      type: currentPet.type,
      breed: currentPet.breed,
      color: currentPet.color,
      dateOfBirth: StrToDate(currentPet.dateOfBirth),
      tag: currentPet.tag,
      tagExpirationDate: StrToDate(currentPet.tagExpirationDate),
      chipNumber: currentPet.chipNumber,
      addressId: addressId!,
      photoUrl: currentPet.photoUrl,
    };

    if (currentPet.photoUrl) {
      const path = currentPet.photoUrl?.split('/');
      const photoName = decodeURIComponent(path[path.length - 1]);
      setFileList([{ uid: photoName, name: photoName, url: currentPet.photoUrl }]);
    }

    form.setFieldsValue(formData);
  }, [currentPet, form]);

  const takePicture = async () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });

      const base64Data = (
        await Filesystem.readFile({
          path: photo.path!,
        })
      ).data as string;

      const date = new Date();
      const fileName = `pet_${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0',
      )}`;

      const file: UploadFile = {
        uid: Date.now().toString(),
        name: `${fileName}.${photo.format}`,
        status: 'done' as UploadFileStatus, // Ensure type is correct here
        type: `image/${photo.format}`,
        size: base64Data.length,
        thumbUrl: photo.webPath!,
      };

      setFileList([file]);
    } catch (e: any) {
      if (e.message === 'User cancelled photos app') {
        console.log('User cancelled the photo operation');
      } else {
        console.log('Unexpected error:', e.message);
      }
    }
  };

  const updateResidentData = () => {
    residentsService
      .getResidentDetails(userAuthStore.webGuestResidentId!)
      .then((data) => {
        eiqConnectStore.setResidentDetails(data);
        navigate('/eiq-connect/pets');
      })
      .catch((e) => {
        notification.error({ message: 'An error occurred while getting resident details' });
      });
  };

  const currentAddressId = useMemo(() => {
    if (!currentPet) {
      return null;
    }

    return eiqConnectStore.residentDetails?.addresses?.find((item) => item.pets.includes(currentPet!))?.address.id;
  }, [currentPet]);

  const createPet = (residentId: number, values: IPetFormData) => {
    const addressId = addressOptions?.length === 1 ? addressOptions[0].value : values.addressId;

    petService
      .createPet(residentId, Number(addressId), toCreatePetModel(values))
      .then(() => {
        isFormSubmittedSuccessful.current = true;
        updateResidentData();
        notification.success({ message: 'The new pet has been successfully added' });
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while updating a pet. Please try again.' });
      });
  };

  const updatePet = (residentId: number, values: IPetFormData) => {
    petService
      .updatePet(residentId, currentAddressId!, currentPet!.id, toUpdatePetModel(values, currentPet!))
      .then(() => {
        isFormSubmittedSuccessful.current = true;
        updateResidentData();
        notification.success({ message: 'The pet has been successfully updated' });
      })
      .catch((e) => {
        notification.error({ message: 'An error occurred while adding a pet. Please try again.' });
      });
  };

  const handleOnFinish = async (values: IPetFormData) => {
    const residentId: number = userAuthStore.webGuestResidentId!;
    if (fileList?.length > 0) {
      if (Capacitor.isNativePlatform() && fileList[0]?.thumbUrl) {
        const blob = await fetch(fileList[0]?.thumbUrl).then((r) => r.blob());
        const file = new File([blob], fileList[0].name, { type: 'image/jpeg' });
        const photoUrl = await FileService.uploadPhoto(file);
        values.photoUrl = photoUrl.absoluteUri;
      } else {
        // Web
        const file = fileList[0]?.originFileObj;
        if (file) {
          const photoUrl = await FileService.uploadPhoto(file);
          values.photoUrl = photoUrl.absoluteUri;
        }
      }
    } else {
      values.photoUrl = '';
    }

    if (pageType === 'ADD') {
      createPet(residentId, values);
    } else {
      updatePet(residentId, values);
    }
  };

  const deletePetModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const residentId: number | null = userAuthStore.webGuestResidentId!;
      const data: IConfirmDeletePetProps = {
        pet: currentPet!,
        residentId: residentId,
        addressId: currentAddressId!,
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        updateResidentData();
        notification.success({ message: 'The pet has been successfully deleted' });
      }
    },
  });

  const props: UploadProps = {
    maxCount: 1,
    listType: 'picture',
    fileList: fileList,
    accept: 'image/png, image/jpeg, image/jpg',
    beforeUpload: () => {
      return false;
    },
    onChange: ({ fileList: newFileList, file }) => {
      if (file && file.size && file.size >= MAX_FILE_SIZE_BYTES) {
        notification.error({ message: 'The image size must be less than 5 MB!' });
      } else {
        setFileList(newFileList);
      }
    },
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url) {
      file.url = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url);
    setPreviewOpen(true);
  };

  const handleManualUpload = (e: any) => {
    if (e.key === 'takePhoto') {
      takePicture();
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'takePhoto',
      label: 'Take a Photo',
      icon: <CameraOutlined />,
      onClick: handleManualUpload,
    },
    {
      key: 'photoLib',
      label: (
        <Upload {...props} fileList={[]} openFileDialogOnClick={true} className="ant-upload">
          Photo Library
        </Upload>
      ),
      icon: <FileImageOutlined />,
    },
  ];

  const isAndroid = Capacitor.getPlatform() === 'android';
  const uploadText = (
    <p className="ant-upload-text">
      <span>{currentPet?.photoUrl ? 'Update' : 'Add'}</span> a photo of your pet
    </p>
  );

  return (
    <FormWrapper
      form={form}
      onFinish={handleOnFinish}
      initialValues={initData}
      layout="vertical"
      className="eiq-vehicle-add-edit eiq-add-edit-page">
      <AddEditPageHeader isMobileOrTablet={isMobileOrTable} pageType={pageType} />
      <div className="eiq-content">
        {pageType === 'ADD' && addressOptions && addressOptions.length > 1 && (
          <Space className="full-width">
            <div className="eiq-car-block">
              <Form.Item name="addressId" label="Address" rules={addEditPetValidation.addressId}>
                <Select options={addressOptions} placeholder="Select address" />
              </Form.Item>
              <Divider className="account-diver" />
            </div>
          </Space>
        )}

        <Space className="full-width">
          <div className="eiq-car-block">
            <Form.Item name="name" label="Name" rules={addEditPetValidation.name}>
              <Input name="name" placeholder="Enter name" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-car-block">
            <Form.Item name="type" label="Type">
              <Input placeholder="Enter type" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-car-block">
            <Form.Item name="breed" label="Breed" rules={addEditPetValidation.breed}>
              <Input placeholder="Enter breed" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-car-block">
            <Form.Item name="color" label="Color" rules={addEditPetValidation.color}>
              <Input placeholder="Enter color" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-car-block">
            <Form.Item name="dateOfBirth" label="Date of birth">
              <DatePicker className="full-width" placeholder="Date of birth" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-car-block">
            <Form.Item name="tag" label="Tag" rules={addEditPetValidation.tag}>
              <Input placeholder="Enter tag" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-car-block">
            <Form.Item name="tagExpirationDate" label="Shots expiration date" rules={addEditPetValidation.tagExpirationDate}>
              <DatePicker className="full-width" placeholder="Shots expiration date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width ant-space-with-uploader">
          <div className="eiq-car-block">
            <Form.Item name="chipNumber" label="Chip number">
              <Input placeholder="Enter chip number" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>

          <Dragger
            {...props}
            openFileDialogOnClick={!isAndroid}
            className="ant-upload"
            onPreview={handlePreview}
            previewFile={getBase64 as any}>
            {isAndroid ? (
              <div>
                <Dropdown rootClassName="android-photo-menu" menu={{ items }} arrow={false}>
                  {uploadText}
                </Dropdown>
              </div>
            ) : (
              uploadText
            )}
          </Dragger>

          <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}>
            <img alt="pet-img" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Space>

        {pageType === 'EDIT' && (
          <Button type="link" onClick={deletePetModal.showModal}>
            Delete pet
          </Button>
        )}
      </div>

      {isMobileOrTable && (
        <div className="eiq-footer-actions">
          <AddEditActions type={pageType} />
        </div>
      )}
      {deletePetModal.isOpenModal && (
        <PetDeleteModal
          isEdit={false}
          initData={deletePetModal?.initData}
          title="Delete"
          isOpen={deletePetModal?.isOpenModal}
          onClose={deletePetModal?.hideModal}
        />
      )}
    </FormWrapper>
  );
});
