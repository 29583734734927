import React, { useCallback } from 'react';
import { useTableContext } from '../../table-context';
import './search-filter.scss';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { HotkeyInput } from '../../../hotkey-input/hotkey-input';
import { HotkeyInputProps } from '../../../hotkey-input/hotkey-input.types';
import { FilterValue } from '../../table.types';
import { debounce } from 'lodash';

export const SearchFilter = ({ rulesForColumn, enableProgressiveSearch, ...rest }: SearchFilterProps) => {
  const tableContext = useTableContext();

  const onSearch = (value: string) => {
    let searchRules: Record<string, null | FilterValue> = {};

    Object.keys(rulesForColumn).forEach((columnKey) => {
      searchRules[columnKey] =
        value?.length > 0 ? { operator: rulesForColumn[columnKey], value: formatValue(value, rulesForColumn[columnKey]) } : null;
    });

    tableContext.updateFilters(searchRules, 'OR');
  };

  const formatValue = (value: string, operator: FilterTypesEnum) => {
    if (operator === FilterTypesEnum.StartWith) {
      return `${value}*`;
    }

    return value;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProgressiveSearch = useCallback(
    debounce((value: string) => {
      if (value.length >= 3) {
        onSearch(value);
      }
    }, 350),
    [rulesForColumn, tableContext],
  );

  const handleProgressiveSearch = (event: any) => {
    if (!enableProgressiveSearch) {
      return;
    }

    const value = event?.target?.value?.trim() ?? '';
    debouncedProgressiveSearch(value);
  };

  return <HotkeyInput className={'search-filter'} {...rest} onSearch={onSearch} onInput={handleProgressiveSearch} />;
};

export interface SearchFilterProps extends HotkeyInputProps {
  rulesForColumn: Record<string, FilterTypesEnum>;
  enableProgressiveSearch?: boolean;
}
