import { Switch } from 'antd';
import React, { useEffect } from 'react';
import TableContextProvider, { useTableControls } from '../../../../../common/components/table/table-context';
import { webGuestShareConfig } from './eiq-connect-share.config';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import {
  IAddressOccupant,
  ISharedResidentAddressData,
  IUpdateResidentAddressDataSharing,
} from 'common/services/guest-service/guest.service.types';
import TableBase from 'common/components/table/table-base';
import { ICellRendererProps } from 'common/components/table/table.types';
import guestService from 'common/services/guest-service/guest.service';
import { observer } from 'mobx-react-lite';
import residentsService from 'common/services/residents-service/residents.service';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';

export const EiqConnectShare = observer(() => {
  const { isMobile } = useMedia();

  const columns = webGuestShareConfig;
  const actionColumnIndex = columns.findIndex((i) => i.key === 'share');
  if (actionColumnIndex !== -1) {
    columns[actionColumnIndex].cellRenderer = ({ rowData }: ICellRendererProps<IAddressOccupant>) => {
      const residentAddressId = eiqConnectStore.residentDetails?.addresses.find((i) => i.address.id === rowData.address.id)?.id;

      if (!residentAddressId) {
        return <div></div>;
      }

      const isShared = rowData.sharedResidentAddressData?.some((i) => i.sharedResidentAddressId === residentAddressId && i.isGuestShared);

      const onCheck = (checked: boolean) => {
        const request: IUpdateResidentAddressDataSharing = {
          residentId: rowData.residentId,
          shareGuestList: checked,
        };

        guestService.updateResidentAddressDataSharing(userAuthStore.webGuestResidentId!, rowData.address.id, request).then((response) => {
          eiqConnectStore.updateSharedResidentAddressId(rowData.residentId, residentAddressId, {
            sharedResidentAddressId: residentAddressId,
            isGuestShared: checked,
          } as ISharedResidentAddressData);
        });
      };

      return <Switch checked={isShared} onChange={onCheck} />;
    };
  }

  useEffect(() => {
    const selectedAddressIndexes = eiqConnectStore.selectedAddressesId as number[];
    const residentId = userAuthStore.webGuestResidentId;
    if (residentId) {
      residentsService.getOccupantsByResidentId(residentId).then((response) => {
        response = response.filter((i) => i.residentId !== +residentId);
        if (selectedAddressIndexes?.length) {
          response = response.filter((i) => eiqConnectStore.selectedAddresses?.some((x) => x?.address.id == i.address.id));
        }
        response.forEach((i, index) => (i.id = index));
        eiqConnectStore.setOccupants(response);
      });
    }
  }, [eiqConnectStore.selectedAddressesId, userAuthStore.webGuestResidentId]);

  const tableControls = useTableControls({
    clientSide: true,
    data: eiqConnectStore.occupants,
    initialColumns: columns,
  });

  return (
    <div className="eiq-guests-page eiq-guests-add-edit">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Share the guest list" showBackText={false} />
      </div>

      <div className="eiq-content">
        <TableContextProvider controls={tableControls}>
          <TableBase wrapperClass="eiq-table" headerHeight={0} fixed={false} rowHeight={isMobile ? 140 : 116} />
        </TableContextProvider>
      </div>
    </div>
  );
});
