import { ServerClient } from '../../utils/http/http-clients';
import { IResponseList } from '../../models/response-list.interface';
import { IParamsLimit } from 'common/models/params-limit.interface';
import { ICitationRead } from './citation-service.types';

class CitationService {
  async getCitationsByResidentId(
    pageLimit: IParamsLimit | null | undefined,
    filter: string | null | undefined,
    sort: string | null | undefined,
    residentId: number,
  ): Promise<IResponseList<ICitationRead>> {
    const res = await ServerClient.get<IResponseList<ICitationRead>>(`/residents/${residentId}/citations`, {
      params: {
        filter,
        ...pageLimit,
        sort,
      },
    });
    return res?.data;
  }

  async createPayment(citationId: number, residentId: number): Promise<any> {
    const res = await ServerClient.post(`/residents/${residentId}/citations/${citationId}/payment/create`);
    return res?.data;
  }
}

export const citationService = new CitationService();
export default citationService;
