import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { useNavigate } from 'react-router-dom';
import { IAccessCardRead } from '../../../../common/services/residents-service/residents.service.types';
import { ReactComponent as GotToDetailsIcon } from 'assets/icons/eiq-connect/got-to-details.svg';
import { Tag } from 'antd';

export const accessCardsListConfig = [
  {
    key: 'col2',
    title: 'DeviceId',
    dataKey: 'col2',
    width: 195,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<IAccessCardRead>) => {
      if (!rowData) {
        return <div></div>;
      }
      return (
        <div className="eiq-column">
          <div className="eiq-column__title">Other Device</div>
          <div className="eiq-column__text">{`Transponder ID - ${rowData.deviceId}`}</div>
        </div>
      );
    },
  },
  {
    key: 'isDeactivated',
    title: 'Deactivated',
    dataKey: 'isDeactivated',
    hidden: true,
    width: 195,
    minWidth: 150,
    cellRenderer: (cell: ICellRendererProps<IAccessCardRead>) => {
      return (
        <>
          {cell.rowData.isDeactivated ? (
            <Tag className="eiq-tag" color="#FFA39E" style={{ color: '#292B2E' }}>
              Deactivated
            </Tag>
          ) : null}
        </>
      );
    },
  },
  {
    key: 'arrow',
    title: 'arrow',
    hidden: true,
    width: 15,
    minWidth: 15,
    cellRenderer: ({ rowData }: ICellRendererProps<IAccessCardRead>) => {
      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <GotToDetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/devices/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
];
