import './eiq-connect-fast-access-pass.scss';
import React, { useState, useEffect } from 'react';
import { Col, Divider, Row, QRCode } from 'antd';
import LogoIcon from 'assets/icons/eiq-connect/eiq-connect-logo.png';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';
import fastAccessService from 'common/services/fast-access-service/fast-access-service';
import { useParams } from 'react-router-dom';
import { IFastAccessPassDetails } from 'common/services/fast-access-service/fast-access-service.types';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { formatCustomDateTime } from 'common/helpers/date.helper';
import { formatEiqPassportCode } from 'common/helpers/eiq-passport.helper';

const FastAccessPass = () => {
  const [fastAccessPass, setFastAccessPass] = useState<IFastAccessPassDetails | null>(null);

  const { communityId, fastAccessPassId } = useParams();

  useEffect(() => {
    if (!communityId || !fastAccessPassId) {
      return;
    }

    communityResolverService.resolveByCommunityId(communityId).then((url) => {
      fastAccessService.getFastAccessPassDetails(fastAccessPassId, url).then((fastAccessPass) => {
        setFastAccessPass(fastAccessPass);
      });
    });
  }, []);

  return (
    <div className="fast-access-pass">
      <div className="logo-wrapper">
        <img className="logo" src={LogoIcon} alt="logo" />
      </div>
      <Row className={'row'}>
        <Col className="col">
          <div className="guest-name">Hello {fastAccessPass?.name},</div>
          <div className="guest-text">Scan your QR-code at the gate</div>
          <div className="fast-access-wrapper">
            <div className="fast-access-qr-code-border">
              {fastAccessPass?.id && <QRCode size={200} value={formatEiqPassportCode(fastAccessPass.id)} bordered={false} />}
            </div>
          </div>
        </Col>
      </Row>
      <div className="community">
        <div className="title">Community</div>
        <div className="text">{fastAccessPass?.community}</div>
      </div>

      <div className="title">Address</div>
      <div className="text">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://maps.google.com/?q=${fastAccessPass?.state}, ${fastAccessPass?.city}, ${formatAddressLabel(
            fastAccessPass?.address,
          )}`}>
          {formatAddressLabel(fastAccessPass?.address)}
        </a>
      </div>
      <Divider className="auth-diver" />
      <div className="title">Valid from</div>
      <div className="text">{formatCustomDateTime(fastAccessPass?.validFrom)}</div>
      <Divider className="auth-diver" />
      <div className="title">Expires</div>
      <div className="text">{formatCustomDateTime(fastAccessPass?.expires)}</div>
      <Divider className="auth-diver" />
    </div>
  );
};

export default FastAccessPass;
