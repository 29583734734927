import { ICellRendererProps } from '../../../../common/components/table/table.types';
import { Button, Tag } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/eiq-connect/edit.svg';
import { useNavigate } from 'react-router-dom';
import { IGuestRead, IVehicleRead } from '../../../../common/services/residents-service/residents.service.types';
import { ReactComponent as GotToDetailsIcon } from 'assets/icons/eiq-connect/got-to-details.svg';
import { vehicleStatusColorMap, vehicleStatusMap } from '../../../../common/constans/common.constants';
import { VehicleStatusEnum } from '../../../../common/enums/vehicle-status.enum';

export const vehiclesListConfig = [
  {
    key: 'col1',
    title: 'Name',
    dataKey: 'col1',
    width: 195,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<IVehicleRead>) => {
      if (!rowData) {
        return <div></div>;
      }
      return (
        <div className="eiq-column">
          <div className="eiq-column__title">{`${rowData.make} ${rowData.model}`}</div>
          <div className="eiq-column__text">{`${rowData.licenseState} ${rowData.licenseNumber}`}</div>
          <div className="eiq-column__text">{rowData.year}</div>
        </div>
      );
    },
  },
  {
    key: 'status',
    title: 'Status',
    dataKey: 'status',
    width: 195,
    minWidth: 150,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return cell.rowData.status ? (
        <Tag className="eiq-tag" color={vehicleStatusColorMap[cell?.cellData as VehicleStatusEnum]} style={{ color: '#292B2E' }}>
          {vehicleStatusMap[cell?.rowData?.status]}
        </Tag>
      ) : null;
    },
  },
  {
    key: 'isDeactivated',
    title: 'Deactivated',
    dataKey: 'isDeactivated',
    hidden: true,
    width: 195,
    minWidth: 150,
    cellRenderer: (cell: ICellRendererProps<IVehicleRead>) => {
      return (
        <>
          {cell.rowData.isDeactivated ? (
            <Tag className="eiq-tag" color="#FFA39E" style={{ color: '#292B2E' }}>
              Deactivated
            </Tag>
          ) : null}
          {cell.rowData.isShared ? <Tag className="eiq-tag expired">Shared</Tag> : null}
        </>
      );
    },
  },
  {
    key: 'action',
    title: 'Actions',
    width: 50,
    minWidth: 50,
    cellRenderer: ({ rowData }: ICellRendererProps<IVehicleRead>) => {
      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <Button
              size="large"
              className="round-primary"
              icon={<EditIcon />}
              onClick={() => {
                navigate(`/eiq-connect/vehicles/${rowData.id}/edit`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
  {
    key: 'arrow',
    title: 'arrow',
    hidden: true,
    width: 15,
    minWidth: 15,
    cellRenderer: ({ rowData }: ICellRendererProps<IGuestRead>) => {
      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <GotToDetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/vehicles/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
];
