import { Button, Divider, Form, Input, Space, Switch } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './eiq-connect-account-edit.scss';
import { SaveOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { IResidentDetails, IResidentPhone, IResidentUpdate } from 'common/services/residents-service/residents.service.types';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { getWebGuestAccountEditValidation } from './eiq-connect-account-edit.validation';
import { observer } from 'mobx-react-lite';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { PhoneCard } from 'features/eiq-connect/components/phone-card/phone-card';
import { EditNotificationsBlock } from 'common/components/edit-notifications-block/edit-notifications-block';
import residentsService from 'common/services/residents-service/residents.service';
import { notification } from 'common/utils/notification';
import { DATE_SHORT_MMM_DD_YYYY, DATE_YYYY_MM_DD } from '../../../../../common/constans/date.constants';
import { StrToDate } from '../../../../../common/helpers/date.helper';
import { IEditNotifications } from 'common/models/notifications.interface';
import { getPhoneComparer } from 'features/eiq-connect/helpers/phone.helper';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-dnd';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import { ReactComponent as DragIcon } from 'assets/icons/eiq-connect/drag.svg';
import featureStore from 'common/stores/features/feature.store';
import { ResidentSourceType } from 'common/enums/resident-source-type.enum';

export const EditAccount = observer(() => {
  const [isEditable, setIsEditable] = useState(true);

  const { isMobileOrTable, isMobile, isDesktop } = useMedia();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const validationRules = getWebGuestAccountEditValidation(form);

  const [isVacationEnabled, setIsVacationEnabled] = useState(false);
  const [accountInfo, setAccountInfo] = useState<IResidentDetails | null>(null);
  const isFormSubmittedSuccessful = useRef(false);
  const [phoneOrderEditMode, setPhoneOrderEditMode] = useState(false);

  const notificationTypes: Record<string, keyof IEditNotifications> = {
    isEmailNotificationEnabled: 'isEmailNotificationEnabled',
    isSMSNotificationEnabled: 'isSMSNotificationEnabled',
    isPushNotificationEnabled: 'isPushNotificationEnabled',
  };
  const [notificationType, setNotificationType] = useState<string>(
    Object.keys(notificationTypes).find((key) => eiqConnectStore.residentDetails?.[notificationTypes[key]]) || 'isNotificationEnabled',
  );
  const gridHeight = (fieldsLength: number) =>
    isDesktop ? 174 * Math.ceil(fieldsLength / 2) : isMobile ? 262 * Math.ceil(fieldsLength) : 174 * Math.ceil(fieldsLength);

  useEffect(() => {
    return () => {
      if (!isFormSubmittedSuccessful.current) {
        notification.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (eiqConnectStore.residentDetails?.id) {
      const residentDetails = eiqConnectStore.residentDetails;
      setIsEditable(residentDetails.source === ResidentSourceType.EIQ);
      setAccountInfo(residentDetails);
      form.setFieldsValue({
        id: residentDetails.id,
        firstName: residentDetails.firstName,
        lastName: residentDetails.lastName,
        phones: residentDetails.phones?.sort(getPhoneComparer()),
        email: residentDetails.email,
        confirmEmail: residentDetails.email,
        isEmailNotificationEnabled: residentDetails.isEmailNotificationEnabled,
        isSMSNotificationEnabled: residentDetails.isSMSNotificationEnabled,
        isPushNotificationEnabled: residentDetails.isPushNotificationEnabled,
        vacationStartDate: StrToDate(residentDetails?.vacationStartDate),
        vacationEndDate: StrToDate(residentDetails.vacationEndDate),
      });
      const phonesWithId = residentDetails.phones?.sort(getPhoneComparer()).map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      form.setFieldValue('phones', phonesWithId);

      if (residentDetails.vacationStartDate && residentDetails.vacationEndDate) {
        setIsVacationEnabled(true);
      } else {
        setIsVacationEnabled(false);
      }
    }
  }, [eiqConnectStore.residentDetails]);

  const getDatePeriod = (startDate: any, endDate: any) => {
    return {
      startDate: isVacationEnabled ? startDate.format(DATE_YYYY_MM_DD) : null,
      endDate: isVacationEnabled ? endDate.format(DATE_YYYY_MM_DD) : null,
    };
  };

  const onFinish = async (values: any) => {
    const phonesWithoutId = values.phones.map(({ id, ...rest }: IResidentPhone) => rest);

    const residentToUpdate = {
      id: accountInfo!.id,
      firstName: accountInfo!.firstName,
      lastName: accountInfo!.lastName,
      email: values.email,
      isEmailNotificationEnabled: notificationType === notificationTypes.isEmailNotificationEnabled,
      isSMSNotificationEnabled: notificationType === notificationTypes.isSMSNotificationEnabled,
      isPushNotificationEnabled: notificationType === notificationTypes.isPushNotificationEnabled,
      phones: phonesWithoutId,
      vacationPeriod: getDatePeriod(values.vacationStartDate, values.vacationEndDate),
      pin: values.newPin ? values.newPin : null,
    } as IResidentUpdate;

    await residentsService.updateResident(residentToUpdate);
    const data = await residentsService.getResidentDetails(accountInfo!.id);
    isFormSubmittedSuccessful.current = true;
    eiqConnectStore.setResidentDetails(data);
    navigate('/eiq-connect/account');
    notification.destroy();
    notification.success({ message: 'Account was successfully updated' });
  };

  const handleDatePickerToggle = (checked: boolean) => {
    setIsVacationEnabled(checked);
  };

  const handleEmailChange = () => {
    form.validateFields(['confirmEmail']);
  };

  const handleAddPhone = () => {
    const phones = form.getFieldValue('phones');
    const maxOrder = phones?.slice().sort((a: any, b: any) => b.order - a.order);
    const order = maxOrder[0] ? maxOrder[0].order + 1 : 0;
    const maxId = phones.length > 0 ? Math.max(...phones.map((phone: IResidentPhone) => phone.id)) : 0;
    const id = maxId + 1;
    const updatedPhones = [...(phones || []), { number: '', label: 'Primary', isNotificationEnabled: false, order: order, id: id }];
    setAccountInfo({ ...(accountInfo as any), phones: updatedPhones });
    form.setFieldValue('phones', updatedPhones);
  };

  const handleDeletePhone = async (index: number) => {
    const phones = form.getFieldValue('phones');
    const updatedPhones = phones.filter((_: any, i: number) => i !== index);

    const reorderedPhones = updatedPhones.map((phone: IResidentPhone, idx: number) => ({
      ...phone,
      order: idx,
    }));

    setAccountInfo({ ...(accountInfo as any), phones: reorderedPhones });
    form.setFieldValue('phones', reorderedPhones);
  };

  function handleOnDragEnd(sourceId: string, sourceIndex: number, targetIndex: number) {
    const phones = form.getFieldValue('phones');
    const nextState: IResidentPhone[] = swap(phones, sourceIndex, targetIndex);
    const updatedPhones = nextState.map((phone: IResidentPhone, index: number) => ({
      ...phone,
      order: index,
    }));
    setAccountInfo({ ...(accountInfo as any), phones: updatedPhones });
    form.setFieldValue('phones', updatedPhones);
  }

  const handleOrderChange = async (index: number, direction: 'up' | 'down') => {
    const phones = form.getFieldValue('phones') || [];
    if (!Array.isArray(phones) || phones.length <= 1) return;

    const newOrder = [...phones];
    const swapIndex = direction === 'up' ? index - 1 : index + 1;

    if (swapIndex >= 0 && swapIndex < phones.length) {
      [newOrder[index], newOrder[swapIndex]] = [newOrder[swapIndex], newOrder[index]];

      const updatedPhones = newOrder.map((phone, idx) => ({
        ...phone,
        order: idx,
      }));

      setAccountInfo({ ...(accountInfo as any), phones: updatedPhones });
      form.setFieldValue('phones', updatedPhones);
    }
  };

  const actionButtons = (
    <>
      <Button type="link" onClick={() => navigate(-1)} className="button">
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" icon={<SaveOutlined />} className="eiq-button">
        Save
      </Button>
    </>
  );

  const validatePin = (_: any, value: string) => {
    const currentPin = eiqConnectStore.residentDetails?.pin;

    const newPin = form.getFieldValue('newPin');
    const confirmPin = form.getFieldValue('confirmPin');

    if ((newPin || confirmPin || value) && currentPin !== value) {
      return Promise.reject(new Error('Invalid current pin'));
    }
    return Promise.resolve();
  };

  return (
    <div className="eiq-guests-page eiq-edit-account-page">
      <FormWrapper form={form} onFinish={onFinish} layout="vertical">
        <div className="eiq-header">
          <TopHeader />
          <BottomHeader title="Edit" />

          {!isMobileOrTable && (
            <Space size={10} className="eiq-action">
              {actionButtons}
            </Space>
          )}
        </div>
        <div className="eiq-content">
          <Space className="full-width eiq-account-email-container">
            <Form.Item name="email" label="Email" validateTrigger="onBlur" rules={validationRules.email}>
              <Input name="email" placeholder={'Email'} onBlur={handleEmailChange} disabled={!isEditable} />
            </Form.Item>
            <Form.Item name="confirmEmail" label="Confirm email" validateTrigger="onBlur" rules={validationRules.confirmEmail}>
              <Input name="confirmEmail" placeholder={'Confirm email'} disabled={!isEditable} />
            </Form.Item>
          </Space>
          <Divider className="account-diver" />
          {featureStore.isConnectEditPinEnabled && (
            <>
              <Space className="full-width eiq-account-pin-container">
                <div>
                  <span className="pin-title">Change PIN</span>
                </div>
                <div>
                  <span className="pin-note">
                    PIN number is required to be unique. App will let you know if the number you have chosen is unique or not.
                  </span>
                </div>
                <div className="pin-item">
                  <Form.Item
                    name="oldPin"
                    label="Current PIN"
                    validateTrigger="onBlur"
                    rules={[{ validator: validatePin }]}
                    dependencies={['newPin', 'confirmPin']}>
                    <Input name="oldPin" placeholder="Current pin" />
                  </Form.Item>
                  <Form.Item name="newPin" label="New PIN" validateTrigger="onBlur">
                    <Input name="confirmEmail" placeholder="New pin" />
                  </Form.Item>
                  <Form.Item
                    name="confirmPin"
                    label="Confirm the new PIN"
                    validateTrigger="onBlur"
                    rules={validationRules.confirmPin}
                    dependencies={['newPin']}>
                    <Input name="confirmEmail" placeholder="Confirm pin" />
                  </Form.Item>
                </div>
              </Space>
              <Divider className="account-diver" />
            </>
          )}

          <Space className="full-width">
            <div className="eiq-phones-wrapper">
              <div className="eiq-phones-title">
                <p>Phone numbers</p>
                <Button
                  disabled={!isEditable}
                  className="edit-cancel-btn"
                  type="link"
                  size={'small'}
                  onClick={() => setPhoneOrderEditMode((prevState) => !prevState)}>
                  {phoneOrderEditMode ? 'Cancel' : 'Change Order'}
                </Button>
              </div>
              <Form.List name="phones">
                {(fields) => (
                  <GridContextProvider onChange={handleOnDragEnd}>
                    <GridDropZone
                      disableDrag={!phoneOrderEditMode || !isDesktop}
                      className="phones"
                      id="phones"
                      boxesPerRow={isMobileOrTable ? 1 : 2}
                      rowHeight={isMobile ? 262 : 174}
                      style={{ height: gridHeight(fields.length) }}>
                      {fields.map((field, index) => (
                        <GridItem key={form.getFieldValue('phones')[field.name]?.id}>
                          <div className="eiq-phone-card-wrapper">
                            {phoneOrderEditMode && isDesktop && <DragIcon style={{ cursor: 'grab' }} />}
                            <PhoneCard
                              disabled={!isEditable}
                              field={field}
                              index={index}
                              validationRules={validationRules}
                              accountInfo={accountInfo}
                              handleDeleteEntry={handleDeletePhone}
                              onOrderChange={handleOrderChange}
                              isArrowsShown={phoneOrderEditMode && !isDesktop}
                            />
                          </div>
                        </GridItem>
                      ))}
                    </GridDropZone>
                  </GridContextProvider>
                )}
              </Form.List>
              <Button type="link" onClick={handleAddPhone} disabled={!isEditable}>
                Add Phone number
              </Button>
            </div>
          </Space>
          <Divider className="account-diver" />
          <Space className="eiq-vacation-notification-container">
            <div className="eiq-vacation-container">
              <div className="eiq-vacation">
                <p>Vacation</p>
                <Switch checked={isVacationEnabled} onChange={handleDatePickerToggle} />
              </div>

              {isVacationEnabled && (
                <>
                  <p>Select date range</p>
                  <div className="range-date range-date__content">
                    <Form.Item name="vacationStartDate" label="From" rules={validationRules.vacationStartDate}>
                      <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
                    </Form.Item>

                    <Form.Item name="vacationEndDate" label="To" rules={validationRules.vacationEndDate}>
                      <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>
            <div className="eiq-notification-container">
              <p>Notification</p>
              <EditNotificationsBlock notificationType={notificationType} onChangeNotificationType={setNotificationType} form={form} />
            </div>
          </Space>
        </div>
        {isMobileOrTable && <div className="eiq-footer-actions">{actionButtons}</div>}
      </FormWrapper>
    </div>
  );
});
