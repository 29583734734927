import React, { ReactNode } from 'react';
import { Space } from 'antd';
import TopHeader from '../../../../components/top-header/top-header';
import BottomHeader from '../../../../components/bottom-header/bottom-header';

interface IEiqConnectQRCodeHeaderProps {
  title: string;
  isMobileOrTablet: boolean;
  actions: ReactNode;
}

const EiqConnectFastAccessHeader = ({ title, isMobileOrTablet, actions }: IEiqConnectQRCodeHeaderProps) => (
  <div className="eiq-header">
    <TopHeader />
    <BottomHeader title={title} />

    {!isMobileOrTablet && (
      <Space size={10} className="eiq-action eiq-qr-code-actions">
        {actions}
      </Space>
    )}
  </div>
);

export default EiqConnectFastAccessHeader;
