import { makeAutoObservable, toJS } from 'mobx';
import { IGuestRead, IResidentDetails } from '../../../common/services/residents-service/residents.service.types';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { IWebGuestFilter } from '../pages/guests/eiq-connect-filter/eiq-connect-filter.types';
import { formatAddressLabel } from 'common/helpers/address.helper';
import { IGuestVoiceForm } from '../pages/guests/components/voice-form/voice-form.type';
import { IAddressOccupant, ISharedResidentAddressData } from 'common/services/guest-service/guest.service.types';
import { ICitationFilter } from '../pages/citations/citation-filter/citation-filter.types';

class EiqConnectStore {
  selectedAddressesId: number[] | string[] | null;
  selectedGuest: IGuestRead[] | null;
  residentDetails: IResidentDetails | null;
  addressOptions?: DefaultOptionType[];
  guestFilters?: IWebGuestFilter | null;
  guestVoiceFrom?: IGuestVoiceForm | null;
  addressOccupants: IAddressOccupant[];
  citationFilter?: ICitationFilter | null;

  constructor() {
    makeAutoObservable(this);
    this.selectedAddressesId = null;
    this.residentDetails = null;
    this.selectedGuest = null;
    this.addressOccupants = [];
  }

  setGuestVoiceFrom(voiceFrom?: IGuestVoiceForm | null) {
    this.guestVoiceFrom = voiceFrom;
  }

  setSelectedAddressesId(selectedAddressId: number[] | string[] | null) {
    this.selectedAddressesId = selectedAddressId;

    console.log('selectedAddressId', selectedAddressId);
  }

  setResidentDetails(residentAddresses: IResidentDetails | null) {
    this.residentDetails = residentAddresses;

    this.addressOptions = residentAddresses?.addresses?.map((item, index) => ({
      title: formatAddressLabel(item.address),
      value: index,
    }));
  }

  setSelectedGuest(selectedGuest: IGuestRead[] | null) {
    this.selectedGuest = selectedGuest;
  }

  setGuestFilters(guestFilters: IWebGuestFilter | null) {
    this.guestFilters = guestFilters;
  }

  setCitationFilter(citationFilter: ICitationFilter | null) {
    this.citationFilter = citationFilter;
  }

  setOccupants(occupants: IAddressOccupant[]) {
    this.addressOccupants = occupants;
  }

  updateSharedResidentAddressId(residentId: number, residentAddressId: number, sharedResidentAddress: ISharedResidentAddressData) {
    const occupant = this.addressOccupants.find((i) => i.residentId === residentId);
    if (occupant) {
      const index = occupant.sharedResidentAddressData?.findIndex((i) => i.sharedResidentAddressId === residentAddressId);

      if (index !== -1) {
        const item = occupant.sharedResidentAddressData![index!];
        occupant.sharedResidentAddressData![index!] = {
          ...item,
          isGuestShared: sharedResidentAddress.isGuestShared ?? item.isGuestShared,
          isVehicleShared: sharedResidentAddress.isVehicleShared ?? item.isVehicleShared,
        };
      } else {
        occupant.sharedResidentAddressData!.push(sharedResidentAddress);
      }
    }
  }

  public get occupants() {
    return toJS(this.addressOccupants);
  }

  public get selectedAddresses() {
    return this.selectedAddressesId?.map((index: number | string) => {
      return this.residentDetails?.addresses[+index];
    });
  }
}

const eiqConnectStore = new EiqConnectStore();
export default eiqConnectStore;
