import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { IAddEditPageHeaderProps } from './add-edit-page-header.types';
import { AddEditActions } from '../add-edit-actions/add-edit-actions';
import React from 'react';
import TopHeader from '../top-header/top-header';
import BottomHeader from '../bottom-header/bottom-header';

export const AddEditPageHeader = observer(({ isMobileOrTablet, pageType }: IAddEditPageHeaderProps) => (
  <div className="eiq-header">
    <TopHeader />
    <BottomHeader title={pageType === 'ADD' ? 'Add' : 'Edit'} />

    {!isMobileOrTablet && (
      <Space size={10} className="eiq-action">
        <AddEditActions type={pageType} />
      </Space>
    )}
  </div>
));
