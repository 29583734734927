import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import TableContextProvider, { useTableControls } from 'common/components/table/table-context';
import trafficService from 'common/services/traffic-service/traffic-service';
import { TrafficList } from './traffic-list/traffic-list';
import { trafficListConfig } from './traffic.config';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { SelectAll } from 'features/eiq-connect/constants/common.constant';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { TrafficDetails } from './traffic-details/traffic-details';

export const WGTraffic = observer(() => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        arrow: true,
        time: false,
        gate: false,
      });
    } else {
      tableControls.hideColumns({
        arrow: false,
        time: true,
        gate: true,
      });
    }
  }, [isMobile]);

  useEffect(() => {
    const selectedAddresses = eiqConnectStore.selectedAddressesId;
    const isAll = !selectedAddresses || selectedAddresses?.length === 0 || selectedAddresses?.[0] === SelectAll;

    if (!isAll) {
      const addresses = eiqConnectStore.residentDetails?.addresses;

      if (!addresses) {
        tableControls.resetFilters();
        return;
      }

      const filters = selectedAddresses.map((value) => {
        const index = value as number;
        return { operator: FilterTypesEnum.Equals, value: addresses[index].address.id };
      });

      tableControls.setFilter('addressId', filters, 'AND');
    } else {
      tableControls.resetFilters();
    }
  }, [eiqConnectStore.selectedAddressesId, eiqConnectStore.residentDetails]);

  const tableControls = useTableControls({
    onSelectedRow: (row: any) => {
      if (isMobile) {
        navigate(`/eiq-connect/traffic/${row?.id}/view`);
      }
    },
    onFetch: useCallback(
      (pageLimit: any, filter: string | null | undefined, sort: string | null | undefined) => {
        if (userAuthStore.webGuestResidentId) {
          return trafficService.getTrafficByResidentId(pageLimit, filter, sort, userAuthStore.webGuestResidentId);
        }
      },
      [userAuthStore.webGuestResidentId],
    ),
    initialColumns: trafficListConfig,
  });

  const isBasePath = location.pathname.endsWith('/traffic');
  const isSearchPath = location.pathname.endsWith('/traffic/search');

  return (
    <TableContextProvider controls={tableControls}>
      <TrafficList className={isBasePath || isSearchPath ? null : 'eiq-display-none'} />

      <Routes>
        <Route path="/:id/view" element={<TrafficDetails />} />
      </Routes>
    </TableContextProvider>
  );
});
