import { Space, Button, Tag } from 'antd';
import { DetailRawView } from 'features/eiq-connect/components/detail-raw-view/detail-raw-view';
import { ReactComponent as EditIcon } from 'assets/icons/eiq-connect/edit.svg';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { DateToFormat } from 'common/helpers/date.helper';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { vehicleStatusColorMap, vehicleStatusMap } from '../../../../../common/constans/common.constants';
import { VehicleStatusEnum } from '../../../../../common/enums/vehicle-status.enum';
import featureStore from 'common/stores/features/feature.store';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';

export const VehicleDetails = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vehicle, setVehicle] = useState<IVehicleRead>();

  useEffect(() => {
    if (!eiqConnectStore.residentDetails || !id) {
      return;
    }

    const vehicle = eiqConnectStore.residentDetails.addresses.flatMap((item) => item.vehicles).find((item) => item.id === Number(id));
    setVehicle(vehicle);
  }, [eiqConnectStore.residentDetails]);

  const formatAddress = useMemo(() => {
    if (!vehicle) {
      return '-';
    }

    const address = eiqConnectStore.residentDetails?.addresses.find((item) => item.vehicles.includes(vehicle))?.address;
    return `${address?.streetNumber} ${address?.street.name}`;
  }, [vehicle]);

  return (
    <div className="eiq-guests-page">
      <div className="eiq-header">
        <TopHeader>
          <Space size={10} className="eiq-action">
            <Button
              size="large"
              className="round-primary"
              onClick={() => navigate(`/eiq-connect/vehicles/${id}/edit`)}
              icon={<EditIcon />}
            />
          </Space>
        </TopHeader>
        <BottomHeader title="Details" />
      </div>

      <div className="eiq-content">
        <div className="w-100">
          <DetailRawView title="Address" value={formatAddress} />
          <DetailRawView title="Make" value={vehicle?.make} />
          <DetailRawView title="Model" value={vehicle?.model} />
          <DetailRawView title="State" value={vehicle?.licenseState} />
          <DetailRawView title="License Plate" value={vehicle?.licenseNumber} />
          <DetailRawView title="Year" value={vehicle?.year} />
          <DetailRawView title="Color" value={vehicle?.color} />
          <DetailRawView title="Owner" value={vehicle?.owner} />
          <DetailRawView title="Registration address" value={vehicle?.registrationAddress || '-'} />
          {vehicle?.status && (
            <div className="eiq-details-row">
              <div className="eiq-details-row__title">Status</div>
              <Tag className="eiq-tag" color={vehicleStatusColorMap[vehicle?.status as VehicleStatusEnum]} style={{ color: '#292B2E' }}>
                {vehicleStatusMap[vehicle.status]}
              </Tag>

              {vehicle?.isDeactivated && (
                <Tag className="eiq-tag" color="#FFA39E" style={{ color: '#292B2E' }}>
                  Deactivated
                </Tag>
              )}
              {vehicle?.isShared && (
                <Tag className="eiq-tag" color="#FFA39E" style={{ color: '#292B2E' }}>
                  Shared
                </Tag>
              )}
            </div>
          )}

          {vehicle?.rentStartDate && vehicle?.rentEndDate && (
            <>
              <DetailRawView title="Rent start date" value={DateToFormat(vehicle?.rentStartDate, DATE_SHORT_MMM_DD_YYYY)} />
              <DetailRawView title="Rent end date" value={DateToFormat(vehicle?.rentEndDate, DATE_SHORT_MMM_DD_YYYY)} />
            </>
          )}
          {featureStore.isCA3000ExpirationsEnabled && (
            <>
              <div className="eiq-details-row">
                <div className="eiq-details-row__title">Validity period</div>
                {vehicle?.validTo && vehicle?.validFrom ? (
                  <div className="eiq-details-row__text">{`${DateToFormat(vehicle?.validFrom, DATE_SHORT_MMM_DD_YYYY)} - ${DateToFormat(
                    vehicle?.validTo,
                    DATE_SHORT_MMM_DD_YYYY,
                  )}`}</div>
                ) : (
                  '-'
                )}
              </div>
              <DetailRawView title="Transponder" value={vehicle?.isNoTransponder ? 'Not available' : 'Available'} />
            </>
          )}
        </div>
      </div>
    </div>
  );
});
