import { residentNotFoundErrorMessage } from 'common/constans/alert-messages.constants';
import { isEmail } from 'common/helpers/email.helper';
import { getLogin } from 'common/helpers/login.helper';
import { isPhoneNumber } from 'common/helpers/phone.helper';
import { ValidationMessages } from 'common/validation/validation-messages';

export const loginValidation = {
  login: [
    { required: true, message: 'Please enter your email!' },
    () => ({
      validator(_: any, value: string) {
        value = getLogin(value);
        const isEmailCorrectFormat = isEmail(value);
        const isPhoneCorrectFormat = isPhoneNumber(value);

        if (!value || isEmailCorrectFormat || isPhoneCorrectFormat) {
          return Promise.resolve();
        }

        return Promise.reject(new Error(residentNotFoundErrorMessage));
      },
    }),
  ],
  password: [{ required: true, message: 'Please enter your password!' }],
};

export const signUpValidation = {
  login: [
    { required: true, message: 'Please enter your email!' },
    () => ({
      validator(_: any, value: string) {
        value = getLogin(value);
        const isEmailCorrectFormat = isEmail(value);
        const isPhoneCorrectFormat = isPhoneNumber(value);

        if (!value || isEmailCorrectFormat || isPhoneCorrectFormat) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Wrong email format! Formats should be as follows - email@mail.com '));
      },
    }),
  ],
  password: [{ required: true, message: 'Please enter your password!' }],
  pinCode: [{ required: true, message: 'Please enter your PIN code!' }],
  verificationCode: [{ required: true, message: 'Please enter your verification code!' }],
};

export const resetPassword = {
  login: [
    { required: true, message: 'Please enter your email!' },
    () => ({
      validator(_: any, value: string) {
        value = getLogin(value);
        const isEmailCorrectFormat = isEmail(value);
        const isPhoneCorrectFormat = isPhoneNumber(value);

        if (!value || isEmailCorrectFormat || isPhoneCorrectFormat) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Wrong email format! Formats should be as follows - email@mail.com '));
      },
    }),
  ],
  password: [
    { required: true, message: 'Please enter your password!' },
    { min: 8, message: ValidationMessages.Password.RequiredLengthMessage },
  ],
  password2: [
    { required: true, message: 'Please enter your password!' },
    ({ getFieldValue }: any) => ({
      validator: (_: any, value: string) => {
        const passwordValue = getFieldValue('password');
        if (!value || passwordValue === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords don't match.");
      },
    }),
  ],
};
