import { Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTableContext } from '../../../../../common/components/table/table-context';
import eiqConnectStore from '../../../store/eiq-connect.store';
import trafficService from '../../../../../common/services/traffic-service/traffic-service';
import userAuthStore from '../../../../../common/stores/user-auth/user-auth.store';
import { admittanceTypeMap } from 'common/constans/admittance.constants';
import { visitorTypeMap, visitorTypeMapColorMap } from 'common/constans/visitor.constants';
import { AdmittanceType } from '../../../../../common/enums/admittance-type.enum';
import dayjs from 'dayjs';
import { DATE_SHORT_MMM_DD_YYYY, TIME_h_mma } from '../../../../../common/constans/date.constants';
import { ITrafficRead } from '../../../../../common/services/traffic-service/traffic-service.types';
import { getTrafficLogGuestName } from 'common/helpers/traffic-log.helper';
import { TrafficLogVisitorType } from '../../../../../common/constans/common.constants';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import { entryTypeColorMap, entryTypeMap } from 'common/constans/entry-type.constants';
import { EntryType } from 'common/enums/entry-type.enum';

export const TrafficDetails = () => {
  const { id } = useParams();
  const tableContext = useTableContext();
  const selectedRow = tableContext.getSelectedRow();
  const [initialValues, setInitialValues] = useState<ITrafficRead>();
  const webGuestResidentId = userAuthStore.webGuestResidentId;

  useEffect(() => {
    if (selectedRow) {
      setInitialValues(selectedRow);
    } else if (webGuestResidentId) {
      trafficService.getTrafficByResidentId({ offset: 0, limit: 20 }, null, '', webGuestResidentId).then((result) => {
        tableContext.setSelectedRow(result.items.find((item) => item.id === id));
      });
    }
  }, [tableContext.getSelectedRow(), eiqConnectStore.residentDetails]);

  return (
    <div className="eiq-guests-page">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Details" />
      </div>

      <div className="eiq-content">
        <div className="w-100">
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Type of visitor</div>
            <Tag
              className="eiq-tag"
              color={visitorTypeMapColorMap[initialValues?.visitorType as TrafficLogVisitorType]}
              style={{ color: '#292B2E' }}>
              {visitorTypeMap[initialValues?.visitorType as TrafficLogVisitorType]}
            </Tag>
          </div>
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Name</div>
            <div className="eiq-details-row__text">{getTrafficLogGuestName(initialValues)}</div>
          </div>
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Admittance</div>
            <div className="eiq-details-row__text">{admittanceTypeMap[initialValues?.admittanceType as AdmittanceType]}</div>
          </div>
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Date</div>
            <div className="eiq-details-row__text">{dayjs(initialValues?.timestamp).format(DATE_SHORT_MMM_DD_YYYY)}</div>
          </div>
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Time</div>
            <div className="eiq-details-row__text">{dayjs(initialValues?.timestamp).format(TIME_h_mma)}</div>
          </div>
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Gate</div>
            <div className="eiq-details-row__text">{initialValues?.gate}</div>
          </div>
          <div className="eiq-details-row">
            <div className="eiq-details-row__title">Entry type</div>
            <Tag className="eiq-tag" color={entryTypeColorMap[initialValues?.entryType as EntryType]} style={{ color: '#292B2E' }}>
              {entryTypeMap[initialValues?.entryType as EntryType]}
            </Tag>
          </div>
        </div>
      </div>
    </div>
  );
};
