import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GotToDetailsIcon } from 'assets/icons/eiq-connect/got-to-details.svg';
import { ReactComponent as DetailsIcon } from 'assets/icons/eiq-connect/details.svg';
import { ReactComponent as ViolationSpeedIcon } from 'assets/icons/eiq-connect/violating-speed.svg';
import { Tag } from 'antd';
import { DATE_SHORT_MMM_DD_YYYY, TIME_h_mma } from 'common/constans/date.constants';
import dayjs from 'dayjs';
import { CitationStatus, citationStatusColorMap, citationStatusMap } from 'common/enums/citation-status.enum';
import { ICitationRead } from 'common/services/citation-service/citation-service.types';
import { ICellRendererProps } from 'common/components/table/table.types';
import styles from './citations.module.scss';
import { getCitationVisitorName } from 'common/helpers/citation.helper';

export const citationListConfig = [
  {
    key: 'citation',
    title: 'Name',
    dataKey: 'name',
    width: 700,
    minWidth: 160,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <Tag className="eiq-tag" color={citationStatusColorMap[rowData.status as CitationStatus]} style={{ color: '#292B2E' }}>
            {citationStatusMap[rowData.status as CitationStatus]}
          </Tag>
          <div className="eiq-column__title">{getCitationVisitorName(rowData)}</div>
          <div className="eiq-column__text">
            {rowData.licensePlate}, {rowData.licenseState}
          </div>
        </div>
      );
    },
  },
  {
    key: 'occurredAtDate',
    title: 'Date',
    dataKey: 'occurredAt',
    width: 150,
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <div className="eiq-column__text">Date</div>
          <div className="eiq-column__title">{dayjs(rowData.occurredAt).format(DATE_SHORT_MMM_DD_YYYY)}</div>
        </div>
      );
    },
  },
  {
    key: 'occurredAtTime',
    title: 'Time',
    dataKey: 'occurredAt',
    width: 150,
    minWidth: 100,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <div className="eiq-column__text">Time</div>
          <div className="eiq-column__title">{dayjs(rowData.occurredAt).format(TIME_h_mma)}</div>
        </div>
      );
    },
  },
  {
    key: 'speedLimit',
    title: 'Speed Limit',
    dataKey: 'speedLimit',
    width: 150,
    minWidth: 100,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className="eiq-column">
          <div className="eiq-column__text">Speed Limit</div>
          <div className="eiq-column__title">{rowData.speedLimit} mph</div>
        </div>
      );
    },
  },
  {
    key: 'violatingSpeed',
    title: 'Violating Speed',
    dataKey: 'violatingSpeed',
    width: 200,
    minWidth: 180,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div className={`eiq-column ${styles.violationSpeed}`}>
          <ViolationSpeedIcon />
          <div className="eiq-column__text">Violating Speed</div>
        </div>
      );
    },
  },
  {
    key: 'details',
    title: 'details',
    hidden: true,
    width: 50,
    minWidth: 50,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <DetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/citations/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
  {
    key: 'info',
    title: 'Info',
    dataKey: 'info',
    width: 200,
    minWidth: 160,
    flexGrow: 1,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="eiq-column__text">{dayjs(rowData.occurredAt).format(DATE_SHORT_MMM_DD_YYYY)}</div>
          <div className="eiq-column__text">{dayjs(rowData.occurredAt).format(TIME_h_mma)}</div>
          <div className={`eiq-column ${styles.violationSpeed}`}>
            <ViolationSpeedIcon />
            <div className="eiq-column__text">Violating Speed</div>
          </div>
        </div>
      );
    },
  },
  {
    key: 'arrow',
    title: 'arrow',
    hidden: true,
    width: 15,
    minWidth: 15,
    cellRenderer: ({ rowData }: ICellRendererProps<ICitationRead>) => {
      if (!rowData) {
        return <div></div>;
      }

      const CustomComponent = () => {
        const navigate = useNavigate();

        return (
          <div>
            <GotToDetailsIcon
              onClick={() => {
                navigate(`/eiq-connect/citations/${rowData.id}/view`);
              }}
            />
          </div>
        );
      };

      return <CustomComponent />;
    },
  },
];
