import '../../guests/eiq-connect-filter/eiq-connect-filter.scss';
import { Button, Col, Form, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'assets/icons/eiq-connect/check.svg';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { CheckboxGroup } from '../../../../../common/components/form-elements/checkbox-group/checkbox-group';
import { useTableContext } from '../../../../../common/components/table/table-context';
import { FilterTypesEnum } from '../../../../../common/enums/filter-types.enum';
import eiqConnectStore from '../../../store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { FilterType } from '../../../../../common/components/table/table.types';
import { notification } from '../../../../../common/utils/notification';
import { ICitationFilter } from './citation-filter.types';
import { citationStatusOptions } from 'common/constans/violation.constants';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';

export const CitationFilter = observer(() => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const tableContext = useTableContext();

  useEffect(() => {
    if (form && eiqConnectStore.citationFilter) {
      const initState = eiqConnectStore.citationFilter;
      form.setFieldsValue(initState);
    }
    return () => notification.destroy();
  }, [form]);

  const onFinish = (values: ICitationFilter) => {
    let filterValue: FilterType = null;

    if (values.status?.length > 0) {
      filterValue = values.status.map((value) => ({
        operator: FilterTypesEnum.Equals,
        value: value,
      }));
    }

    tableContext.updateFilter('status', filterValue, 'OR');

    eiqConnectStore.setCitationFilter({
      ...eiqConnectStore.citationFilter,
      ...values,
    });

    navigate(-1);
  };

  const resetFilter = () => {
    form.resetFields();
    tableContext.updateFilter('status', null, 'OR');
    eiqConnectStore.setCitationFilter(null);
    navigate(-1);
  };

  const applyCancelButtons = (
    <>
      <Button type="link" className="button" onClick={resetFilter}>
        Reset
      </Button>
      <Button type="primary" htmlType="submit" icon={<CheckIcon />} className="eiq-button">
        Apply
      </Button>
    </>
  );

  return (
    <FormWrapper form={form} onFinish={onFinish} layout="vertical" className="eiq-filter-page eiq-connect-add-edit">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Filter" />

        <Space size={10} className="eiq-action">
          {!isMobileOrTable && applyCancelButtons}
        </Space>
      </div>

      <div className="eiq-content">
        <Row className="w-100" gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item name="status">
              <CheckboxGroup label="Status" options={citationStatusOptions} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      {isMobileOrTable && <div className="eiq-footer-actions">{applyCancelButtons}</div>}
    </FormWrapper>
  );
});
