export enum ResidentTypesEnum {
  Owner = 'Owner',
  Renter = 'Renter',
  Manager = 'Manager',
  Occupant = 'Occupant',
  Spouse = 'Spouse',
  Roommate = 'Roommate',
  Partner = 'Partner',
  SignificantOther = 'SignificantOther',
  AdultChild = 'AdultChild',
  UnderAgeChild = 'UnderAgeChild',
  NonResident = 'NonResident',
  Developer = 'Developer',
  Employee = 'Employee',
  ClubMember = 'ClubMember',
}

export const getResidentTypes = (): ResidentTypesEnum[] => Object.values(ResidentTypesEnum).map((k) => k as ResidentTypesEnum);
