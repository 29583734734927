import React from 'react';
import { observer } from 'mobx-react-lite';
import TableBase from 'common/components/table/table-base';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTableContext } from '../../../../../common/components/table/table-context';
import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { AddressSelect } from 'features/eiq-connect/components/address-select/address-select';
import { SearchFilter } from 'common/components/table/filters/search-filter/search-filter';
import { FilterTypesEnum } from 'common/enums/filter-types.enum';
import { Button, Space } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/icons/eiq-connect/search.svg';
import { ReactComponent as AddIcon } from 'assets/icons/eiq-connect/add.svg';
import featureStore from 'common/stores/features/feature.store';
import '../vehicles.scss';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import { ReactComponent as ShareIcon } from 'assets/icons/eiq-connect/share.svg';
import configurationStore from 'common/stores/configuration/configuration.store';

export const VehiclesList = observer(({ className }: any) => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const location = useLocation();
  const isMainPath = location.pathname.endsWith('/vehicles');
  const isSearchPath = location.pathname.endsWith('/vehicles/search');
  const tableContext = useTableContext();
  const defaultConfiguration = configurationStore.configuration?.default;

  const selectFilter = (
    <SearchFilter
      enableProgressiveSearch={true}
      allowClear={true}
      placeholder="By model"
      style={{ display: isSearchPath ? 'flex' : 'none' }}
      rulesForColumn={{
        model: FilterTypesEnum.Contains,
      }}
    />
  );

  const addressSelect = (
    <AddressSelect
      value={eiqConnectStore.selectedAddressesId ? eiqConnectStore.selectedAddressesId : undefined}
      placeholder="Filter vehicles by your addresses"
      className={isSearchPath ? 'display-none' : undefined}
      treeData={eiqConnectStore.addressOptions}
      onChange={(value) => eiqConnectStore.setSelectedAddressesId(value)}
    />
  );

  const getTitle = () => {
    if (featureStore.isCardAccessEnabled) {
      return (
        <span>
          <span className="active">Vehicles</span>
          <span> | </span>
          <span onClick={() => navigate('/eiq-connect/devices')}>Other Devices</span>
        </span>
      );
    }
    return 'Vehicles';
  };

  const actionButtons = (
    <Space size={10} className="eiq-action">
      {!defaultConfiguration?.isShareVehicleConnect && (
        <Button
          size="large"
          className="round-primary"
          onClick={() => {
            navigate('/eiq-connect/vehicles/share');
          }}
          icon={<ShareIcon />}
        />
      )}
      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          navigate('/eiq-connect/vehicles/search');
        }}
        icon={<SearchIcon />}
      />

      <Button
        size="large"
        className="round-primary"
        onClick={() => {
          navigate('/eiq-connect/vehicles/add');
        }}
        icon={<AddIcon />}
      />
    </Space>
  );
  return (
    <div className={`eiq-guests-page ${className ?? ''}`}>
      <div className={`eiq-header ${isMainPath && 'main-page'}`}>
        <TopHeader>{!isSearchPath && actionButtons}</TopHeader>
        {isSearchPath ? <BottomHeader title="Search" /> : <h1 className="eiq-title title-tabs">{getTitle()}</h1>}
        {!isMobileOrTable && (
          <>
            {selectFilter}
            {addressSelect}
            {!isSearchPath && actionButtons}
          </>
        )}
      </div>
      <div className="eiq-content">
        {isMobileOrTable && selectFilter}

        {tableContext?.dataState?.data?.length > 0 && isSearchPath && <div className="eiq-search-result">Result</div>}

        {isMobileOrTable && addressSelect}

        <TableBase wrapperClass="eiq-table" headerHeight={0} fixed={false} rowHeight={116} />
      </div>
    </div>
  );
});
