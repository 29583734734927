const sortByDesc = (a: string, b: string) => b.length - a.length;

const primaryVerbs: string[] = [
  'might have been',
  'must have been',
  'should have been',
  'could have been',
  'would have been',
  'might have to be',
  'must have to be',
  'should have to be',
  'could have to be',
  'would have to be',
  'has been',
  'have been',
  'had been',
  'might be',
  'must be',
  'should be',
  'could be',
  'would be',
  'will be',
  'shall be',
  'has',
  'have',
  'have to',
  'had',
  'is',
  'are',
  'was',
  'were',
  'can',
  'can be',
].sort(sortByDesc);

const secondaryVerbs: string[] = [
  'considered',
  'required',
  'allowed',
  'forbidden',
  'obliged',
  'meant',
  'supposed',
  'likely',
  'unlikely',
  'able',
  'willing',
  'reluctant',
].sort(sortByDesc);

// Declare the type for extendedVerbs
let extendedVerbs: string[] = [];

primaryVerbs.forEach((pVerb) => {
  secondaryVerbs.forEach((sVerb) => {
    extendedVerbs.push(`${pVerb} ${sVerb} to be`);
    extendedVerbs.push(`${pVerb} ${sVerb} be`); // omitting "to"
  });
});

extendedVerbs.push(...primaryVerbs);
extendedVerbs.push('to be');
extendedVerbs.push('be');
extendedVerbs.push('as');
extendedVerbs.push('with');
extendedVerbs.push('with value');
extendedVerbs.push('with the');
extendedVerbs.push('with an');
extendedVerbs.push('with the value');
extendedVerbs.push('with an value');
// Sort the array by their length from longest to shortest
extendedVerbs.sort(sortByDesc);

console.log(extendedVerbs);

//Start phrases
const startPhrases = [
  'Please,',
  'Please',
  'Pls,',
  'Pls',
  'Could you',
  'Would you mind',
  "I'd like you to",
  'Kindly add',
  'Can you add',
  'I request you to',
  'Could you please',
  'Would it be possible to',
].sort(sortByDesc);

const secondStartPhrases: string[] = [
  'add',
  'change',
  'replace',
  'set',
  'add field',
  'chang field',
  'replace field',
  'set field',
  'add column',
  'chang column',
  'replace column',
  'set column',
  'column',
  'field',
].sort(sortByDesc);

let extendedStartPhrases: string[] = [];

startPhrases.forEach((pVerb) => {
  secondStartPhrases.forEach((sVerb) => {
    extendedStartPhrases.push(`${pVerb} ${sVerb}`);
  });
});

extendedStartPhrases.push();

export { extendedVerbs, extendedStartPhrases };
