import './guests.scss';
import React, { useEffect, useMemo } from 'react';
import { TableContextProvider, useTableControls } from '../../../../common/components/table/table-context';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { IEIQConnectRead, webGuestsListConfig } from './guests.config';
import { EiqConnectList } from './eiq-connect-list/eiq-connect-list';
import { EiqConnectAddEdit } from './eiq-connect-guests-add-edit/eiq-connect-add-edit';
import { EiqConnectFilter } from './eiq-connect-filter/eiq-connect-filter';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { EiqConnectDetails } from './eiq-connect-details/eiq-connect-details';
import { EiqConnectShare } from './eiq-connect-share/eiq-connect-share';
import eiqConnectStore from '../../store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { IResidentAddresses } from '../../../../common/services/residents-service/residents.service.types';
import { StrToDateTimeLocal } from '../../../../common/helpers/date.helper';
import { mapToGuestValidity } from '../../../../common/helpers/guest-validity.helper';
import { guestTypeMap } from 'common/constans/guest-type-options.constant';
import EiqConnectFastAccess from './eiq-connect-fast-access/eiq-connect-fast-access';
import { FeatureName } from 'common/enums/feature-name.enum';
import featureStore from 'common/stores/features/feature.store';
import configurationStore from 'common/stores/configuration/configuration.store';
import { SortBy } from 'common/components/table/table.types';
import { Sorters } from './eiq-connect-filter/eiq-connect-sorters.consts';

const selectAll = 'SelectAll';
const defaultSortBy: SortBy = Sorters[0];

export const Guests = observer(() => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const defaultConfiguration = configurationStore.configuration?.default;

  const data = useMemo(() => {
    const selectedAddresses = eiqConnectStore.selectedAddressesId;

    const isAll = !selectedAddresses || selectedAddresses?.length === 0 || selectedAddresses?.[0] === selectAll;

    if (isAll) {
      return eiqConnectStore.residentDetails?.addresses?.reduce(
        (acc, curr) =>
          acc.concat(
            curr.guests.map((guest) => {
              return {
                ...guest,
                validity: mapToGuestValidity(guest.validity, StrToDateTimeLocal(guest?.visitDate)),
                typeToSearch: guestTypeMap[guest.type],
                address: `${curr.address.streetNumber} ${curr.address.street.name} `,
                addressId: curr.address.id,
              };
            }) as any,
          ),
        [],
      );
    } else if (selectedAddresses?.length > 0) {
      const guests: IEIQConnectRead[] = [];

      selectedAddresses?.forEach((value) => {
        const index = value as number;
        const currentAddress = JSON.parse(JSON.stringify(eiqConnectStore.residentDetails?.addresses[index])) as IResidentAddresses;

        if (!currentAddress) {
          return;
        }
        const guest = currentAddress.guests.map((guest) => {
          return {
            ...guest,
            validity: mapToGuestValidity(guest.validity, StrToDateTimeLocal(guest?.visitDate)),
            typeToSearch: guestTypeMap[guest.type],
            address: `${currentAddress.address.streetNumber} ${currentAddress.address.street.name} `,
            addressId: currentAddress.address.id,
          };
        });
        guests.push(...guest);
      });

      return guests;
    }

    return [];
  }, [eiqConnectStore.residentDetails, eiqConnectStore.selectedAddressesId]);

  const tableControls = useTableControls({
    onSelectedRow: (row: any) => {
      if (isMobile) {
        navigate(`/eiq-connect/guests/${row?.id}/view`);
      }
    },
    defaultSort: defaultSortBy,
    clientSide: true,
    data: data,
    initialColumns: webGuestsListConfig,
  });

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        validity: false,
        daysOfWeek: false,
        action: false,
        arrow: true,
      });
    } else {
      tableControls.hideColumns({
        validity: true,
        daysOfWeek: true,
        action: true,
        arrow: false,
      });
    }
  }, [isMobile]);

  const location = useLocation();
  const isBasePath = location.pathname.endsWith('/guests');
  const isSearchPath = location.pathname.endsWith('/guests/search');

  return (
    <TableContextProvider controls={tableControls}>
      {(isBasePath || isSearchPath) && <EiqConnectList className={isBasePath || isSearchPath ? null : 'eiq-display-none'} />}

      <Routes>
        <Route path="filter" element={<EiqConnectFilter />} />
        <Route path="add" element={<EiqConnectAddEdit />} />
        {!defaultConfiguration?.isShareGuestConnect && <Route path="share" element={<EiqConnectShare />} />}
        <Route path="/:id/edit" element={<EiqConnectAddEdit />} />
        <Route path="/:id/view" element={<EiqConnectDetails />} />
        {featureStore.IsEnabled(FeatureName.FastAccess) && <Route path="/:id/eiq-passport" element={<EiqConnectFastAccess />} />}
      </Routes>
    </TableContextProvider>
  );
});
