import { IDetailRawViewProps } from './detail-raw-view.types';

export const DetailRawView = ({ title, value }: IDetailRawViewProps) => {
  return (
    <div className="eiq-details-row">
      <div className="eiq-details-row__title">{title}</div>
      <div className="eiq-details-row__text">{value}</div>
    </div>
  );
};
