import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { IOwnershipRestrictRoutePropes } from './ownershipt-specific-route.types';

export const OwnershiptSpecificRoute = observer(({ ownerships, element, children }: IOwnershipRestrictRoutePropes): any => {
  const residentOwnershipts = eiqConnectStore.residentDetails?.addresses.map((address) => address.ownershipType);
  const isAllowed = residentOwnershipts?.some((ownership) => ownerships.includes(ownership));

  if (!eiqConnectStore.residentDetails || isAllowed) {
    return element || children ? element || children : <Outlet />;
  } else {
    return <h1>You do not have access to this page</h1>;
  }
});
