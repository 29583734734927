import { Button, Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { loginValidation } from '../../validation/login.validation';
import { observer } from 'mobx-react-lite';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as LoginIcon } from 'assets/icons/eiq-connect/login.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eiq-connect/eye.svg';
import { ReactComponent as EyeCrossIcon } from 'assets/icons/eiq-connect/eye-cross.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import './login.scss';
import Spinner from '../../../../common/components/base/spinner/spinner';
import { trackPromise } from 'react-promise-tracker';
import { notification } from '../../../../common/utils/notification';
import { residentNotFoundErrorMessage, residentDeletionRequestedErrorMessage } from 'common/constans/alert-messages.constants';
import { ResidentDeletionRequested } from 'common/constans/errorCodes.constants';
import { getLogin } from 'common/helpers/login.helper';
import { KEEP_ME_LOGGED_IN } from 'common/stores/user-auth/user-auth.constants';

export const WGLoginPage = () => {
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem('isSuccessfullySaved');
    notification.destroy();
  }, []);

  const onLoginFailure = (error: any) => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    if (error?.errorCode === ResidentDeletionRequested) {
      notification.destroy();
      notification.error({ message: residentDeletionRequestedErrorMessage });
    } else {
      notification.destroy();
      notification.error({ message: residentNotFoundErrorMessage });
    }
  };

  const onFinish = (values: any) => {
    trackPromise(userAuthStore.requestTokenForResident(getLogin(values.login), values.password, values.recaptcha, onLoginFailure));
  };

  if (userAuthStore.isLogin) {
    return <Navigate to="/eiq-connect" />;
  }

  const handleKeepMeLoggedInChanged = (event: any) => {
    userAuthStore.setKeepMeLoggedIn(event.target.checked);
  };

  return (
    <>
      <FormWrapper
        className="eiq-connect-auth-form-wrapper"
        form={form}
        onFinish={onFinish}
        name="validateOnly"
        layout="vertical"
        autoComplete="on"
        initialValues={{ remember: true }}>
        <Row>
          <Col>
            <div className="title-wrapper">
              <div className="title active">Log in</div>
              <div
                className="title"
                onClick={() => {
                  navigate('/sign-up');
                }}>
                Account activation
              </div>
            </div>
            <div className="text">Please use the email you have registered with your association to log in.</div>
          </Col>
        </Row>

        <div>
          <Form.Item name="login" label="Email" rules={loginValidation.login}>
            <Input name="login" placeholder="Enter email" autoCapitalize="none" autoCorrect="off" />
          </Form.Item>

          <Divider className="auth-diver" />

          <Form.Item name="password" label="Password (Min 8 characters)" rules={loginValidation.password}>
            <Input.Password
              size={'large'}
              name="password"
              placeholder="Enter password"
              iconRender={(visible) => {
                if (visible) {
                  return <EyeCrossIcon />;
                }
                return <EyeIcon />;
              }}
            />
          </Form.Item>
          <div className="keep-me-logged-in">
            <span>Keep me logged in</span>
            <Checkbox checked={userAuthStore.keepMeLoggedIn} onChange={handleKeepMeLoggedInChanged} />
          </div>
          <Form.Item name="recaptcha" rules={[{ required: true, message: 'Please verify you are not a robot' }]}>
            <ReCAPTCHA ref={recaptchaRef} className="recaptcha" sitekey={siteKey ?? ''} />
          </Form.Item>
        </div>

        <div className="action-line">
          <Button htmlType="submit" type="primary" icon={<LoginIcon />} style={{ width: '100%' }}>
            Log In
          </Button>
        </div>
        <div className="text text-center">
          <Link to="/forgot-password">Forgot the password?</Link>
        </div>
      </FormWrapper>
      <Spinner />
    </>
  );
};

export const WGLogin = observer(WGLoginPage);
