import React, { useEffect, useMemo } from 'react';
import { TableContextProvider, useTableControls } from '../../../../common/components/table/table-context';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import eiqConnectStore from '../../store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { IPetRead, IResidentAddresses } from '../../../../common/services/residents-service/residents.service.types';
import { SelectAll } from 'features/eiq-connect/constants/common.constant';
import { petsListConfig } from './pets.config';
import { PetsList } from './pets-list/pets-list';
import { PetDetails } from './pet-details/pet-details';
import { PetAddEdit } from './pet-add-edit/pet-add-edit';

const Pets = observer(() => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();

  const data = useMemo(() => {
    const selectedAddresses = eiqConnectStore.selectedAddressesId;
    const isAll = !selectedAddresses || selectedAddresses?.length === 0 || selectedAddresses?.[0] === SelectAll;
    if (isAll) {
      return eiqConnectStore.residentDetails?.addresses?.reduce(
        (acc, curr) =>
          acc.concat(
            curr.pets.map((pet) => {
              return {
                ...pet,
                address: curr.address,
              };
            }) as any,
          ),
        [],
      );
    } else if (selectedAddresses?.length > 0) {
      const pets: IPetRead[] = [];

      selectedAddresses?.forEach((value) => {
        const index = value as number;

        if (!eiqConnectStore.residentDetails?.addresses[index]) {
          return;
        }

        const currentAddress: IResidentAddresses = { ...eiqConnectStore.residentDetails?.addresses[index] };
        const pet = currentAddress.pets.map((pet) => {
          return {
            ...pet,
            address: currentAddress.address,
          };
        });
        pets.push(...pet);
      });

      return pets;
    }

    return [];
  }, [eiqConnectStore.residentDetails, eiqConnectStore.selectedAddressesId]);

  const tableControls = useTableControls({
    onSelectedRow: (row: any) => {
      if (isMobile) {
        navigate(`/eiq-connect/pets/${row?.id}/view`);
      }
    },
    clientSide: true,
    data: data,
    initialColumns: petsListConfig,
  });

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        action: false,
        arrow: true,
      });
    } else {
      tableControls.hideColumns({
        action: true,
        arrow: false,
      });
    }
  }, [isMobile]);

  const location = useLocation();
  const isBasePath = location.pathname.endsWith('/pets');
  const isSearchPath = location.pathname.endsWith('/pets/search');

  return (
    <TableContextProvider controls={tableControls}>
      {(isBasePath || isSearchPath) && <PetsList className={isBasePath || isSearchPath ? null : 'eiq-display-none'} />}

      <Routes>
        <Route path="add" element={<PetAddEdit pageType="ADD" />} />
        <Route path="/:id/edit" element={<PetAddEdit pageType="EDIT" />} />
        <Route path="/:id/view" element={<PetDetails />} />
      </Routes>
    </TableContextProvider>
  );
});

export default Pets;
