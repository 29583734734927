import React from 'react';
import './phone-card.scss';
import { Form, Select } from 'antd';
import { PhoneCardProps } from './phone-card.types';
import InputPhone from 'common/components/form-elements/input-phone/input-phone';
import { ReactComponent as Trash } from 'assets/icons/eiq-connect/trash.svg';
import { ReactComponent as UpIcon } from 'assets/icons/eiq-connect/up.svg';
import { ReactComponent as DownIcon } from 'assets/icons/eiq-connect/down.svg';

const { Option } = Select;

export const PhoneCard: React.FC<PhoneCardProps> = ({
  field,
  index,
  validationRules,
  accountInfo,
  handleDeleteEntry,
  onOrderChange,
  isArrowsShown,
  disabled,
}) => (
  <div className="eiq-phone-card">
    <div className="eiq-phones-inputs">
      <Form.Item
        {...field}
        key={`${field.key}_number`}
        className="eiq-phone-input"
        name={[field.name, 'number']}
        label="Phone number"
        validateTrigger="onBlur"
        rules={validationRules.phone}>
        <InputPhone placeholder="Enter phone" disabled={disabled} />
      </Form.Item>
      <Form.Item {...field} key={`${field.key}_label`} className="eiq-account-edit-select" name={[field.name, 'label']} label="Label">
        <Select disabled={disabled}>
          <Option value="Primary">Primary</Option>
          <Option value="Mobile">Mobile</Option>
          <Option value="Home">Home</Option>
          <Option value="Work">Work</Option>
          <Option value="Emergency">Emergency</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>
    </div>
    <div className={`eiq-phone-card-footer ${isArrowsShown && 'arrows-shown'}`}>
      {isArrowsShown && (
        <div className="call-order">
          <span>Call Order</span>
          <UpIcon className={`${index === 0 ? 'disabled' : ''}`} onClick={() => onOrderChange(index, 'up')} />
          <DownIcon
            className={`${index === accountInfo!.phones?.length - 1 ? 'disabled' : ''}`}
            onClick={() => onOrderChange(index, 'down')}
          />
        </div>
      )}
      {accountInfo!.phones.length > 1 && !disabled && (
        <Trash className="eiq-phones-delete-btn" onClick={() => handleDeleteEntry(index)}></Trash>
      )}
    </div>
  </div>
);
