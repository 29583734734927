import { UseModalHookProps, UseModalHookType } from './use-modal.type';
import { useState } from 'react';
import { notification } from '../../utils/notification';

export const useModal = (props?: UseModalHookProps): UseModalHookType => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [initData, setModalInitData] = useState<any>(false);
  const { onBeforeShow, onAsyncBeforeShow, onClose } = props ?? {};

  const showModal = (...rest: any) => {
    if (onBeforeShow) {
      onBeforeShow(setModalInitData, ...rest);
      setOpenModal(true);
    }
    if (onAsyncBeforeShow) {
      onAsyncBeforeShow(setModalInitData, ...rest)
        .then(() => {
          setOpenModal(true);
        })
        .catch((e) => {
          notification.error({
            message: 'An error occurred while loading data, please try again.',
          });
          console.error('onAsyncBeforeShow', e);
        });
    } else {
      setOpenModal(true);
    }
  };

  const hideModal = (isSuccessSaved?: boolean, ...rest: any) => {
    setOpenModal(false);

    if (onClose) {
      onClose(isSuccessSaved, ...rest);
    }
  };

  return {
    isOpenModal,
    showModal,
    hideModal,
    initData,
  };
};
