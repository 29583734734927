import './eiq-connect-filter.scss';
import { Button, Col, Divider, Form, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useMedia } from '../../../../../common/hooks/use-media/use-media.hook';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'assets/icons/eiq-connect/check.svg';
import { daysOfWeekCheckboxOptionsFull } from 'common/constans/days-of-week.constants';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { CheckboxGroup } from '../../../../../common/components/form-elements/checkbox-group/checkbox-group';
import { useTableContext } from '../../../../../common/components/table/table-context';
import { FilterTypesEnum } from '../../../../../common/enums/filter-types.enum';
import eiqConnectStore from '../../../store/eiq-connect.store';
import { IWebGuestFilter } from './eiq-connect-filter.types';
import { observer } from 'mobx-react-lite';
import { notification } from '../../../../../common/utils/notification';
import { guestTypeOptions } from '../../../../../common/constans/guest-type-options.constant';
import TopHeader from '../../../components/top-header/top-header';
import BottomHeader from '../../../components/bottom-header/bottom-header';
import { GuestSortOptions, Sorters } from './eiq-connect-sorters.consts';
import RadioGroup from 'common/components/form-elements/radio-group/radio-group';

const renderApplyCancelButtons = (onReset: () => void) => (
  <>
    <Button type="link" className="button" onClick={onReset}>
      Reset
    </Button>
    <Button type="primary" htmlType="submit" icon={<CheckIcon />} className="eiq-button">
      Apply
    </Button>
  </>
);

export const EiqConnectFilter = observer(() => {
  const { isMobileOrTable } = useMedia();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const tableContext = useTableContext();
  const defaultSortByOption = 0;

  const mobileDivider = isMobileOrTable ? <Divider className="eiq-divider" /> : null;

  useEffect(() => {
    if (form && eiqConnectStore.guestFilters) {
      const initState = eiqConnectStore.guestFilters;
      form.setFieldsValue(initState);
    }
    return () => notification.destroy();
  }, [form]);

  const applyTypeFilter = (types?: string[]) => {
    const filterValue = types?.length
      ? types.map((type) => ({
          operator: FilterTypesEnum.Equals,
          value: type,
        }))
      : null;

    tableContext.updateFilter('type', filterValue, 'OR');
  };

  const applyDaysOfWeekFilter = (daysOfWeek?: string[]) => {
    const dayOfFilter = daysOfWeek?.join(', ');
    const daysOfWeekFilter = dayOfFilter ? { operator: FilterTypesEnum.Contains, value: dayOfFilter } : null;

    tableContext.updateFilter('daysOfWeek', daysOfWeekFilter, 'AND');
  };

  const applySortBy = () => {
    const sortByValue = form.getFieldValue('sortBy');
    tableContext.setSortBy(Sorters[sortByValue]);
  };

  const onFinish = (values: IWebGuestFilter) => {
    const { daysOfWeek, type } = values;

    applyDaysOfWeekFilter(daysOfWeek);
    applyTypeFilter(type);
    applySortBy();

    eiqConnectStore.setGuestFilters({
      ...eiqConnectStore.guestFilters,
      ...values,
    });

    navigate(-1);
  };

  const resetFilter = () => {
    form.resetFields();
    tableContext.resetFilters();
    tableContext.updateFilter('daysOfWeek', null, 'OR');
    tableContext.updateFilter('type', null, 'OR');
    tableContext.setSortBy(Sorters[defaultSortByOption]);
    eiqConnectStore.setGuestFilters(null);
    navigate(-1);
  };

  return (
    <FormWrapper form={form} onFinish={onFinish} layout="vertical" className="eiq-filter-page eiq-connect-add-edit">
      <div className="eiq-header">
        <TopHeader />
        <BottomHeader title="Sort & Filter" />

        <Space size={10} className="eiq-action">
          {!isMobileOrTable && renderApplyCancelButtons(resetFilter)}
        </Space>
      </div>

      <div className="eiq-content">
        <Row className="w-100" gutter={24}>
          <Col xs={24} lg={12}>
            <Form.Item name="type" label="Type">
              <CheckboxGroup options={guestTypeOptions} />
            </Form.Item>
          </Col>

          {mobileDivider}

          <Col xs={24} lg={12}>
            <Form.Item name="daysOfWeek" label="Days allowed to visit">
              <CheckboxGroup options={daysOfWeekCheckboxOptionsFull} />
            </Form.Item>
          </Col>

          {mobileDivider}

          <Col xs={24} lg={12}>
            <Form.Item name="sortBy" label="Sort by" initialValue={defaultSortByOption}>
              <RadioGroup options={GuestSortOptions.options}></RadioGroup>
            </Form.Item>
          </Col>
        </Row>
      </div>

      {isMobileOrTable && <div className="eiq-footer-actions">{renderApplyCancelButtons(resetFilter)}</div>}
    </FormWrapper>
  );
});
