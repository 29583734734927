import { TreeSelect } from 'antd';
import { observer } from 'mobx-react-lite';
import { IAddressSelectProps } from './address-select.types';
import './address-select.scss';
export const AddressSelect = observer(({ value, className, treeData, onChange, placeholder, defaultValue }: IAddressSelectProps) => {
  return (
    <>
      {
        <TreeSelect
          value={value}
          onChange={onChange}
          popupClassName="eiq-select-days"
          className={`eiq-select-address ${className ?? ''}`}
          treeDefaultExpandAll={true}
          treeCheckable={true}
          treeData={treeData}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          maxTagCount={1}
          filterTreeNode={(search, item) => {
            return (item?.title as string)?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
          }}
          showSearch={true}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      }
    </>
  );
});
