import './vehicle-add-edit.scss';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { observer } from 'mobx-react-lite';
import { IAddEditVehicleProps, IVehicleFormData } from './vehicle-add-edit.types';
import { toCreateVehicleModel, toUpdateVehicleModel } from './vehicle-add-edit.helpers';
import { Button, DatePicker, Divider, Form, Input, Select, SelectProps, Space, Switch } from 'antd';
import { useMedia } from 'common/hooks/use-media/use-media.hook';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { AddEditPageHeader } from 'features/eiq-connect/components/add-edit-page-header/add-edit-page-header';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import eiqConnectStore from 'features/eiq-connect/store/eiq-connect.store';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import residentsService from 'common/services/residents-service/residents.service';
import { AddEditActions } from 'features/eiq-connect/components/add-edit-actions/add-edit-actions';
import { DATE_SHORT_MMM_DD_YYYY } from 'common/constans/date.constants';
import { notification } from 'common/utils/notification';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';
import { StrToDate } from 'common/helpers/date.helper';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import { formatAddressLabel } from 'common/helpers/address.helper';
import SelectState from 'common/components/form-elements/select-state/select-state';
import configurationStore from 'common/stores/configuration/configuration.store';
import VehicleDeleteModal from '../vehicle-delete-modal/vehicle-delete-modal';
import { Upload, Modal } from 'antd';
import type { UploadProps } from 'antd';
import FileService from '../../../../../common/services/file-service/file.service';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { MAX_FILE_SIZE_BYTES } from '../../../../../common/constans/common.constants';
import { getBase64 } from '../../../../../common/helpers/getBase64.helper';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import { addEditVehicleValidation } from 'common/validation/vehicle.validation';
import { IConfirmDeleteVehicleProps } from '../vehicle-delete-modal/vehicle-delete-modal.types';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { CameraOutlined, FileImageOutlined } from '@ant-design/icons';
import featureStore from 'common/stores/features/feature.store';
import FormItem from 'antd/es/form/FormItem';

const { Dragger } = Upload;
const defaultLicenseState = configurationStore.configuration?.default.defaultLicenseState;

const initData: IVehicleFormData = {
  year: null,
  make: null,
  model: null,
  licenseNumber: null,
  licenseState: defaultLicenseState ?? null,
  color: null,
  owner: null,
  registrationAddress: null,
  addressId: null,
  rentStartDate: null,
  rentEndDate: null,
  photoUrl: null,
  isNoTransponder: false,
};

export const VehicleAddEdit = observer(({ pageType }: IAddEditVehicleProps) => {
  const [form] = Form.useForm();
  const { isMobileOrTable, isMobile } = useMedia();
  const { id } = useParams();
  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [addressOptions, setAddressOptions] = useState<SelectProps['options']>([]);
  const [isRented, setIsRented] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState<IVehicleRead>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const isFormSubmittedSuccessful = useRef(false);

  useEffect(() => {
    return () => {
      if (!isFormSubmittedSuccessful.current) {
        notification.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (!eiqConnectStore.residentDetails) {
      return;
    }

    const addressOptions = eiqConnectStore.residentDetails?.addresses?.map((item) => ({
      value: item.address.id,
      label: formatAddressLabel(item.address),
    }));

    setAddressOptions(addressOptions || []);

    if (pageType === 'EDIT') {
      const vehicle = eiqConnectStore.residentDetails.addresses.flatMap((item) => item.vehicles).find((item) => item.id === Number(id!));
      setCurrentVehicle(vehicle);
    }
  }, [eiqConnectStore.residentDetails]);

  useEffect(() => {
    if (!form || !currentVehicle) {
      return;
    }

    const addressId = eiqConnectStore.residentDetails?.addresses?.find((item) => item.vehicles.includes(currentVehicle))?.address.id;

    const formData: IVehicleFormData = {
      year: currentVehicle.year,
      make: currentVehicle.make,
      model: currentVehicle.model,
      licenseNumber: currentVehicle.licenseNumber,
      licenseState: currentVehicle.licenseState,
      color: currentVehicle.color,
      owner: currentVehicle.owner,
      registrationAddress: currentVehicle.registrationAddress,
      addressId: addressId!,
      rentStartDate: StrToDate(currentVehicle.rentStartDate),
      rentEndDate: StrToDate(currentVehicle.rentEndDate),
      photoUrl: currentVehicle.photoUrl,
      isNoTransponder: currentVehicle.isNoTransponder,
    };

    if (currentVehicle.photoUrl) {
      const path = currentVehicle.photoUrl?.split('/');
      const photoName = decodeURIComponent(path[path.length - 1]);
      setFileList([{ uid: photoName, name: photoName, url: currentVehicle.photoUrl }]);
    }

    setIsRented(currentVehicle.rentStartDate !== null && currentVehicle.rentEndDate !== null);

    form.setFieldsValue(formData);
  }, [currentVehicle, form]);

  const takePicture = async () => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });

      const base64Data = (
        await Filesystem.readFile({
          path: photo.path!,
        })
      ).data as string;

      const date = new Date();
      const fileName = `vehicle_registration_${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
        date.getDate(),
      ).padStart(2, '0')}`;

      const file: UploadFile = {
        uid: Date.now().toString(),
        name: `${fileName}.${photo.format}`,
        status: 'done' as UploadFileStatus, // Ensure type is correct here
        type: `image/${photo.format}`,
        size: base64Data.length,
        thumbUrl: photo.webPath!,
      };

      setFileList([file]);
    } catch (e: any) {
      if (e.message === 'User cancelled photos app') {
        console.log('User cancelled the photo operation');
      } else {
        console.log('Unexpected error:', e.message);
      }
    }
  };

  const updateResidentData = () => {
    residentsService
      .getResidentDetails(userAuthStore.webGuestResidentId!)
      .then((data) => {
        eiqConnectStore.setResidentDetails(data);
        navigate('/eiq-connect/vehicles');
      })
      .catch((e) => {
        notification.error({ message: 'An error occurred while getting resident details' });
      });
  };

  const currentAddressId = useMemo(() => {
    if (!currentVehicle) {
      return null;
    }

    return eiqConnectStore.residentDetails?.addresses?.find((item) => item.vehicles.includes(currentVehicle!))?.address.id;
  }, [currentVehicle]);

  const createVehicle = (residentId: number, values: IVehicleFormData) => {
    const addressId = addressOptions?.length === 1 ? addressOptions[0].value : values.addressId;

    vehicleService
      .createVehicle(residentId, Number(addressId), toCreateVehicleModel(values))
      .then(() => {
        isFormSubmittedSuccessful.current = true;
        updateResidentData();
        notification.success({ message: 'The new vehicle has been successfully added' });
      })
      .catch(() => {
        notification.error({ message: 'An error occurred while updating a vehicle. Please try again.' });
      });
  };

  const updateVehicle = (residentId: number, values: IVehicleFormData) => {
    vehicleService
      .updateVehicle(residentId, currentAddressId!, toUpdateVehicleModel(values, currentVehicle!))
      .then(() => {
        isFormSubmittedSuccessful.current = true;
        updateResidentData();
        notification.success({ message: 'The vehicle has been successfully updated' });
      })
      .catch((e) => {
        notification.error({ message: 'An error occurred while adding a vehicle. Please try again.' });
      });
  };

  const handleOnFinish = async (values: IVehicleFormData) => {
    const residentId: number = userAuthStore.webGuestResidentId!;
    if (fileList?.length > 0) {
      if (Capacitor.isNativePlatform() && fileList[0]?.thumbUrl) {
        const blob = await fetch(fileList[0]?.thumbUrl).then((r) => r.blob());
        const file = new File([blob], fileList[0].name, { type: 'image/jpeg' });
        const photoUrl = await FileService.uploadPhoto(file);
        values.photoUrl = photoUrl.absoluteUri;
      } else {
        // Web
        const file = fileList[0]?.originFileObj;
        if (file) {
          const photoUrl = await FileService.uploadPhoto(file);
          values.photoUrl = photoUrl.absoluteUri;
        }
      }
    } else {
      values.photoUrl = '';
    }

    if (pageType === 'ADD') {
      createVehicle(residentId, values);
    } else {
      updateVehicle(residentId, values);
    }
  };

  const handleRentedToggle = (checked: boolean) => {
    setIsRented(checked);
  };

  const deleteVehicleModal = useModal({
    onBeforeShow: (setModalInitData) => {
      const residentId: number | null = userAuthStore.webGuestResidentId!;
      const data: IConfirmDeleteVehicleProps = {
        vehicle: currentVehicle!,
        residentId: residentId,
        addressId: currentAddressId!,
      };

      setModalInitData(data);
    },
    onClose: (isSuccess) => {
      if (isSuccess) {
        updateResidentData();
        notification.success({ message: 'The vehicle has been successfully deleted' });
      }
    },
  });

  const props: UploadProps = {
    maxCount: 1,
    listType: 'picture',
    fileList: fileList,
    accept: 'image/png, image/jpeg, image/jpg',
    beforeUpload: () => {
      return false;
    },
    onChange: ({ fileList: newFileList, file }) => {
      if (file && file.size && file.size >= MAX_FILE_SIZE_BYTES) {
        notification.error({ message: 'The image size must be less than 5 MB!' });
      } else {
        setFileList(newFileList);
      }
    },
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url) {
      file.url = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url);
    setPreviewOpen(true);
  };

  const handleManualUpload = (e: any) => {
    if (e.key === 'takePhoto') {
      takePicture();
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'takePhoto',
      label: 'Take a Photo',
      icon: <CameraOutlined />,
      onClick: handleManualUpload,
    },
    {
      key: 'photoLib',
      label: (
        <Upload {...props} fileList={[]} openFileDialogOnClick={true} className="ant-upload">
          Photo Library
        </Upload>
      ),
      icon: <FileImageOutlined />,
    },
  ];

  const isAndroid = Capacitor.getPlatform() === 'android';
  const uploadText = (
    <p className="ant-upload-text">
      <span>{currentVehicle?.photoUrl ? 'Update' : 'Add'}</span> a copy of your vehicle registration
    </p>
  );

  return (
    <FormWrapper
      form={form}
      onFinish={handleOnFinish}
      initialValues={initData}
      layout="vertical"
      className="eiq-vehicle-add-edit eiq-add-edit-page">
      <AddEditPageHeader isMobileOrTablet={isMobileOrTable} pageType={pageType} />
      <div className="eiq-content">
        {pageType === 'ADD' && addressOptions && addressOptions.length > 1 && (
          <Space className="full-width">
            <div className="eiq-vehicle-block">
              <Form.Item name="addressId" label="Address" rules={addEditVehicleValidation.addressId}>
                <Select options={addressOptions} placeholder="Select address" />
              </Form.Item>
              <Divider className="account-diver" />
            </div>
          </Space>
        )}

        <Space className="full-width">
          <div className="eiq-vehicle-block">
            <Form.Item name="owner" label="Owner" rules={addEditVehicleValidation.owner}>
              <Input name="owner" placeholder="Enter owner" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-vehicle-block">
            <Form.Item name="year" label="Year" rules={addEditVehicleValidation.year}>
              <Input name="year" placeholder="Enter year" type="number" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-vehicle-block">
            <Form.Item name="make" label="Make" rules={addEditVehicleValidation.make}>
              <Input name="make" placeholder="Enter make" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-vehicle-block">
            <Form.Item name="model" label="Model" rules={addEditVehicleValidation.model}>
              <Input name="model" placeholder="Enter model" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-vehicle-block">
            <Form.Item name="color" label="Color" rules={addEditVehicleValidation.color}>
              <Input name="color" placeholder="Enter color" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width">
          <div className="eiq-vehicle-block">
            <Form.Item name="licenseNumber" label="License plate" rules={addEditVehicleValidation.licenseNumber}>
              <Input name="licenseNumber" placeholder="Enter license plate" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div className="eiq-vehicle-block">
            <Form.Item name="licenseState" label="State" rules={addEditVehicleValidation.licenseState}>
              <SelectState placeholder="Select a state" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
        </Space>

        <Space className="full-width ant-space-with-uploader">
          <div className="eiq-vehicle-block">
            <Form.Item name="registrationAddress" label="Registration address">
              <Input name="registrationAddress" placeholder="Enter registration address" />
            </Form.Item>
            <Divider className="account-diver" />
          </div>
          <div>
            <Dragger
              {...props}
              openFileDialogOnClick={!isAndroid}
              className="ant-upload"
              onPreview={handlePreview}
              previewFile={getBase64 as any}>
              {isAndroid ? (
                <div>
                  <Dropdown rootClassName="android-photo-menu" menu={{ items }} arrow={false}>
                    {uploadText}
                  </Dropdown>
                </div>
              ) : (
                uploadText
              )}
            </Dragger>

            <Modal open={previewOpen} footer={null} onCancel={() => setPreviewOpen(false)}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {isMobile && <Divider className="account-diver" />}
          </div>
        </Space>

        <Space className="full-width">
          {featureStore.isCardAccessEnabled && (
            <div className="eiq-vehicle-block">
              <Form.Item name="isNoTransponder" label="No Transponder" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Divider className="account-diver" />
            </div>
          )}
          <div className="eiq-vehicle-block">
            <FormItem label="Rented" valuePropName="checked">
              <Switch checked={isRented} onChange={handleRentedToggle} />
            </FormItem>
            <Divider className="account-diver" />
          </div>
        </Space>

        {isRented && (
          <Space className="full-width eiq-date-range">
            <Form.Item name="rentStartDate" label="From" rules={addEditVehicleValidation.rentStartDate}>
              <DatePicker placeholder="Start date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
            <Form.Item name="rentEndDate" label="To" rules={addEditVehicleValidation.rentEndDate}>
              <DatePicker placeholder="End date" format={DATE_SHORT_MMM_DD_YYYY} />
            </Form.Item>
          </Space>
        )}

        {pageType === 'EDIT' && (
          <Button type="link" onClick={deleteVehicleModal.showModal}>
            Delete vehicle
          </Button>
        )}
      </div>

      {isMobileOrTable && (
        <div className="eiq-footer-actions">
          <AddEditActions type={pageType} />
        </div>
      )}

      <VehicleDeleteModal
        isEdit={false}
        initData={deleteVehicleModal?.initData}
        title="Delete"
        isOpen={deleteVehicleModal?.isOpenModal}
        onClose={deleteVehicleModal?.hideModal}
      />
    </FormWrapper>
  );
});
