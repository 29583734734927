import React from 'react';
import { Outlet } from 'react-router-dom';
import './login-layout.scss';
import LogoIcon from 'assets/icons/eiq-connect/eiq-connect-logo.png';

export const LoginLayout = () => {
  return (
    <div className="eiq-login-layout">
      <div className="eiq-auth-wrapper">
        <div className="auth-log">
          <img className="logo" src={LogoIcon} alt="logo" />
        </div>
        <Outlet />
      </div>
    </div>
  );
};
