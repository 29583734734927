import { ReactComponent as BackIcon } from 'assets/icons/eiq-connect/back.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface BottomHeaderProps {
  title: string;
  showBackText?: boolean;
}

const BottomHeader: React.FC<BottomHeaderProps> = ({ title, showBackText = true }) => {
  const navigate = useNavigate();
  return (
    <div className="eiq-bottom-header">
      <span className="eiq-back-btn" onClick={() => navigate(-1)}>
        <BackIcon />
        {showBackText && 'Back'}
      </span>
      <h1 className="eiq-title">{title}</h1>
    </div>
  );
};

export default BottomHeader;
