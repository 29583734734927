import { ServerClient } from 'common/utils/http/http-clients';
import { ISpeechToken } from './speech.service.types';
import { OutputFormat, PullAudioInputStreamCallback, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import {
  Recognizer,
  SessionEventArgs,
  SpeechRecognitionCanceledEventArgs,
  SpeechRecognitionEventArgs,
  SpeechRecognitionResult,
} from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/sdk/Exports';
import communityResolverService from '../community-resolver-service/community-resolver.service';

class SpeechService {
  private authorizationToken?: string;
  private region?: string;
  public useDetailedResults: boolean = false;
  private audioFile: Blob | null = null;
  private microphoneSourceValue: string | null = null;
  private audioChunks: Blob[] = [];
  private mediaRecorder: any;
  private recognizer?: SpeechRecognizer;
  private onFullResult?: (text: string) => void;
  private onFullRecognizing?: (text: string) => void;
  private fullText?: string[];

  constructor() {
    this.audioChunks = [];
    this.mediaRecorder = null;

    // Initialize media stream within the constructor or call this.initializeMediaStream() elsewhere
    this.initializeMediaStream();
  }

  initializeMediaStream() {}

  onDataAvailable(event: BlobEvent) {
    this.audioChunks.push(event.data);
  }

  onStop() {
    this.audioFile = new Blob(this.audioChunks, { type: 'audio/wav' });
    const audioUrl: string = URL.createObjectURL(this.audioFile);
    const audio: HTMLAudioElement = new Audio(audioUrl);
    audio.play();
  }

  setOnRecognizing(callBack?: (text: string) => void) {
    this.onFullRecognizing = callBack;
  }
  // Old approach with audi file, there is problem with format
  startRecording() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);

        // Set up data available event
        this.mediaRecorder.ondataavailable = this.onDataAvailable.bind(this);

        // Set up stop event
        this.mediaRecorder.onstop = this.onStop.bind(this);

        // Start record
        this.audioChunks = [];
        this.mediaRecorder.start();
      })
      .catch((err) => {
        console.error('Could not get user media:', err);
      });
  }

  stopRecording() {
    this.mediaRecorder.stop();
  }

  async getToken(): Promise<ISpeechToken> {
    const url = new URL(communityResolverService.getCurrentCommunityUrl() || '');
    const baseUrl = `${url.protocol}//${url.hostname}:${url.port}`;
    const res = await ServerClient.get<ISpeechToken>(`/speech/issue-token`, {
      baseURL: baseUrl,
    });
    return res?.data;
  }

  async updateToken() {
    const res = await this.getToken();
    this.authorizationToken = res.token;
    this.region = res.region;
  }

  // Method to set the audio blob
  setAudio(audioBlob: Blob | null) {
    this.audioFile = audioBlob;
  }

  // Method to set the microphone source
  setMicrophoneSource(microphoneSourceValue: string | null) {
    this.microphoneSourceValue = microphoneSourceValue;
  }

  // Method to enumerate microphones
  enumerateMicrophones() {
    return new Promise((resolve) => {
      if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log(`Unable to query for audio input devices. Default will be used.\r\n`);
        resolve([]);
        return;
      }

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const mics = devices
          .filter((device) => device.kind === 'audioinput')
          .map((device) => ({
            label: device.label,
            value: device.deviceId,
          }));
        resolve(mics);
      });
    });
  }

  async doRecognizeOnceAsync() {
    // if (!this.audioFile) {
    //   return;
    // }
    //
    // const audioStream = SpeechSDK.AudioInputStream.createPushStream();
    //
    // // Assuming you have a getAudioStream method like before
    // const arrayBuffer = await this.audioFile.arrayBuffer();
    // audioStream.write(arrayBuffer);
    // audioStream.close();
    //
    // const audioConfig =  SpeechSDK.AudioConfig.fromStreamInput(audioStream);

    const audioConfig = this.getAudioConfig(); // Assume you've defined getAudioConfig
    const speechConfig = this.getSpeechConfig();

    if (!audioConfig || !speechConfig) {
      throw new Error('Configuration missing'); // Or other error handling
    }

    const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    this.applyCommonConfigurationTo(recognizer);

    // @ts-ignore
    recognizer.recognized = undefined;

    return new Promise((resolve, reject) => {
      recognizer.recognizeOnceAsync(
        (result) => {
          resolve(this.onRecognizedResult(result));
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  async startRecognizing(onFullResult?: (text: string) => void) {
    const audioConfig = this.getAudioConfig(); // Assume you've defined getAudioConfig
    const speechConfig = this.getSpeechConfig();

    if (!audioConfig || !speechConfig) {
      throw new Error('Configuration missing'); // Or other error handling
    }

    this.fullText = [];
    this.onFullResult = onFullResult;
    this.recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    this.applyCommonConfigurationTo(this.recognizer);

    return new Promise((resolve, reject) => {
      this.recognizer?.startContinuousRecognitionAsync(
        () => {
          resolve('Listening...');
        },
        (error) => {
          console.error(`An error occurred: ${error}`);
          reject(error);
        },
      );
    });
  }

  async stopRecognizing() {
    if (!this.recognizer) {
      return;
    }

    this.recognizer.stopContinuousRecognitionAsync(
      () => {
        if (!this.fullText) {
          return;
        }

        const fullText = this.fullText.join(' ');
        if (this.onFullResult) {
          this.onFullResult(fullText);
        }

        console.log('Recognition stopped.');
      },
      (err) => {
        console.log(`Error stopping: ${err}`);
      },
    );

    this.recognizer = undefined;
  }

  static getAudioStream(audioFile: Blob, cb: (buffer: ArrayBuffer) => void): void {
    const fr = new FileReader();
    fr.readAsArrayBuffer(audioFile);
    fr.addEventListener(
      'loadend',
      (e) => {
        const buf = e.target?.result;
        if (buf) {
          cb(buf as ArrayBuffer);
        }
      },
      false,
    );
  }

  // static getAudioConfigFromStream(audioFile: Blob | null) {
  //   if (audioFile) {
  //     //const audioFormat = SpeechSDK.AudioStreamFormat.getWaveFormatPCM(16000, 16, 1);
  //     const audioStream = SpeechSDK.AudioInputStream.createPushStream();
  //
  //     this.getAudioStream(audioFile, (b: ArrayBuffer) => {
  //       // @ts-ignore
  //       audioStream.write(b.slice());
  //       audioStream.close();
  //     });
  //
  //     return SpeechSDK.AudioConfig.fromStreamInput(audioStream);
  //   } else {
  //     alert('Please choose a file when selecting file input as your audio source.');
  //     return null;
  //   }
  // }

  // getAudioConfig() {
  //   // If an audio file was specified, use it. Otherwise, use the microphone.
  //   // Depending on browser security settings, the user may be prompted to allow microphone use. Using
  //   // continuous recognition allows multiple phrases to be recognized from a single use authorization.
  //   if (audioFile) {
  //     return SpeechSDK.AudioConfig.fromWavFileInput(audioFile);
  //   } else if (inputSourceFileRadio.checked) {
  //     alert('Please choose a file when selecting file input as your audio source.');
  //     return;
  //   } else if (microphoneSources.value) {
  //     return SpeechSDK.AudioConfig.fromMicrophoneInput(microphoneSources.value);
  //   } else {
  //     return SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  //   }
  // }

  // getAudioConfig = async () => {
  //   if (this.audioFile) {
  //     const audioStream = SpeechSDK.AudioInputStream.createPushStream();
  //
  //     // Convert the audio/webm Blob to audio/wav ArrayBuffer
  //     //const wavBuffer = await convertWebmToWav(this.audioFile);
  //
  //     audioStream.write(wavBuffer);
  //     audioStream.close();
  //
  //     return SpeechSDK.AudioConfig.fromStreamInput(audioStream);
  //   } else if (this.microphoneSourceValue) {
  //     return SpeechSDK.AudioConfig.fromMicrophoneInput(this.microphoneSourceValue);
  //   } else {
  //     return SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  //   }
  // };

  getAudioConfig = () => {
    if (this.audioFile) {
      // let readOffset = 0;
      //
      // const fileReader = new FileReader();
      //
      // fileReader.onload = (event) => {
      //   this.audioArrayBuffer = event?.target?.result as ArrayBuffer;
      // };
      //
      // fileReader.readAsArrayBuffer(this.audioFile);
      //
      // const pullStreamCallback: PullAudioInputStreamCallback = {
      //   read: (buffer: ArrayBuffer): number => {
      //     if (!this.audioArrayBuffer) {
      //       return 0;
      //     }
      //
      //     const remainingBytes = this.audioArrayBuffer.byteLength - readOffset;
      //     const bytesToRead = Math.min(buffer.byteLength, remainingBytes);
      //
      //     if (bytesToRead <= 0) {
      //       return 0; // End of stream
      //     }
      //
      //     const view = new Uint8Array(this.audioArrayBuffer, readOffset, bytesToRead);
      //     new Uint8Array(buffer).set(view);
      //
      //     readOffset += bytesToRead;
      //
      //     return bytesToRead;
      //   },
      //   close: (): void => {
      //     readOffset = 0; // Reset the read offset when closing the stream
      //   },
      // };
      //
      // const pullStream = SpeechSDK.AudioInputStream.createPullStream(pullStreamCallback);
      // return SpeechSDK.AudioConfig.fromStreamInput(pullStream);
      //const audioFormat = SpeechSDK.AudioStreamFormat.getWaveFormatPCM(16000, 16, 1);
      // Uncomment if you want to use a custom audio format
      const audioFormat = SpeechSDK.AudioStreamFormat.getWaveFormatPCM(16000, 16, 1);
      const audioStream = SpeechSDK.AudioInputStream.createPushStream();

      console.log('Created audio stream', audioStream);

      // Assuming you have a getAudioStream method like before
      SpeechService.getAudioStream(this.audioFile, (b: ArrayBuffer) => {
        // @ts-ignore
        audioStream.write(b);
        audioStream.close();
      });

      // // @ts-ignore
      // const audioConfig = SpeechSDK.AudioConfig.fromWavFileInput(audioStream); //, audioFormat
      // console.log('Created audio config', audioConfig);
      //
      // // Convert the Blob to a File object (assuming it's in WAV format)
      // const audioFile = new File([this.audioFile], 'recording.wav', { type: 'audio/wav' });
      //
      // // Create AudioConfig from the File
      // return SpeechSDK.AudioConfig.fromWavFileInput(audioFile);

      return SpeechSDK.AudioConfig.fromStreamInput(audioStream); //, audioFormat
    } else if (this.microphoneSourceValue) {
      return SpeechSDK.AudioConfig.fromMicrophoneInput(this.microphoneSourceValue);
    } else {
      return SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    }
  };

  // getSpeechConfig(sdkConfigType) {
  //   let speechConfig;
  //   if (authorizationToken) {
  //     speechConfig = sdkConfigType.fromAuthorizationToken(authorizationToken, regionOptions.value);
  //   } else if (!key.value) {
  //     alert('Please enter your Cognitive Services Speech subscription key!');
  //     return undefined;
  //   } else {
  //     speechConfig = sdkConfigType.fromSubscription(key.value, regionOptions.value);
  //   }
  //
  //   // Setting the result output format to Detailed will request that the underlying
  //   // result JSON include alternates, confidence scores, lexical forms, and other
  //   // advanced information.
  //   if (useDetailedResults && sdkConfigType != SpeechSDK.SpeechConfig) {
  //     window.console.log('Detailed results are not supported for this scenario.\r\n');
  //     document.getElementById('formatSimpleRadio').click();
  //   } else if (useDetailedResults) {
  //     speechConfig.outputFormat = SpeechSDK.OutputFormat.Detailed;
  //   }
  //
  //   // Defines the language(s) that speech should be translated to.
  //   // Multiple languages can be specified for text translation and will be returned in a map.
  //   if (sdkConfigType == SpeechSDK.SpeechTranslationConfig) {
  //     speechConfig.addTargetLanguage(languageTargetOptions.value.substring(0, 5));
  //   }
  //
  //   speechConfig.speechRecognitionLanguage = languageOptions.value;
  //   return speechConfig;
  // }

  private getSpeechConfig = (): SpeechConfig | undefined => {
    let speechConfig: SpeechConfig;

    if (this.authorizationToken && this.region) {
      speechConfig = SpeechConfig.fromAuthorizationToken(this.authorizationToken, this.region);
    } else {
      return undefined;
    }
    // else if (!this.key) {
    //   alert('Please enter your Cognitive Services Speech subscription key!');
    //   return undefined;
    // } else {
    //   speechConfig = SpeechConfig.fromSubscription(this.key, this.region);
    // }

    if (this.useDetailedResults) {
      speechConfig.outputFormat = OutputFormat.Detailed;
    }

    speechConfig.speechRecognitionLanguage = 'en-US';
    return speechConfig;
  };
  onRecognizing = (sender: Recognizer, recognitionEventArgs: SpeechRecognitionEventArgs) => {
    const result = recognitionEventArgs.result;
    console.log(`(recognizing) Reason: ${SpeechSDK.ResultReason[result.reason]}` + ` Text: ${result.text}\r\n`);
  };

  onRecognized = (sender: Recognizer, recognitionEventArgs: SpeechRecognitionEventArgs) => {
    const result = this.onRecognizedResult(recognitionEventArgs.result);

    if (result.text && this.fullText) {
      this.fullText?.push(result.text);
      if (this.onFullRecognizing) {
        this.onFullRecognizing(this.fullText.join(' '));
      }
    }
  };

  // onRecognizedResult = (result: SpeechRecognitionResult) => {
  //   console.log(`(recognized)  Reason: ${SpeechSDK.ResultReason[result.reason]}`);
  //
  //   switch (result.reason) {
  //     case SpeechSDK.ResultReason.NoMatch:
  //       var noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(result);
  //       ` NoMatchReason: ${SpeechSDK.NoMatchReason[noMatchDetail.reason]}\r\n`;
  //       break;
  //     case SpeechSDK.ResultReason.Canceled:
  //       var cancelDetails = SpeechSDK.CancellationDetails.fromResult(result);
  //       ` CancellationReason: ${SpeechSDK.CancellationReason[cancelDetails.reason]}`;
  //       console.error(cancelDetails.reason === SpeechSDK.CancellationReason.Error ? `: ${cancelDetails.errorDetails}` : ``);
  //       break;
  //     case SpeechSDK.ResultReason.RecognizedSpeech:
  //     case SpeechSDK.ResultReason.TranslatedSpeech:
  //     case SpeechSDK.ResultReason.RecognizedIntent:
  //       if (useDetailedResults) {
  //         var detailedResultJson = JSON.parse(result.json);
  //
  //         // Detailed result JSON includes substantial extra information:
  //         //  detailedResultJson['NBest'] is an array of recognition alternates
  //         //  detailedResultJson['NBest'][0] is the highest-confidence alternate
  //         //  ...['Confidence'] is the raw confidence score of an alternate
  //         //  ...['Lexical'] and others provide different result forms
  //         var displayText = detailedResultJson['DisplayText'];
  //         phraseDiv.innerHTML += `Detailed result for "${displayText}":\r\n` + `${JSON.stringify(detailedResultJson, null, 2)}\r\n`;
  //       } else if (result.text) {
  //         phraseDiv.innerHTML += `${result.text}\r\n`;
  //       }
  //
  //       var intentJson = result.properties.getProperty(SpeechSDK.PropertyId.LanguageUnderstandingServiceResponse_JsonResult);
  //       if (intentJson) {
  //         phraseDiv.innerHTML += `${intentJson}\r\n`;
  //       }
  //       break;
  //   }
  // };

  onRecognizedResult = (result: SpeechRecognitionResult) => {
    console.log(`(recognized)  Reason: ${SpeechSDK.ResultReason[result.reason]}`);

    let processedResult: any = {}; // Initialize processed result to an empty object

    switch (result.reason) {
      case SpeechSDK.ResultReason.NoMatch:
        const noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(result);
        console.log(` NoMatchReason: ${SpeechSDK.NoMatchReason[noMatchDetail.reason]}`);
        processedResult.reason = 'NoMatch';
        processedResult.detail = SpeechSDK.NoMatchReason[noMatchDetail.reason];
        break;
      case SpeechSDK.ResultReason.Canceled:
        const cancelDetails = SpeechSDK.CancellationDetails.fromResult(result);
        console.log(` CancellationReason: ${SpeechSDK.CancellationReason[cancelDetails.reason]}`);
        console.error(cancelDetails.reason === SpeechSDK.CancellationReason.Error ? `: ${cancelDetails.errorDetails}` : ``);
        processedResult.reason = 'Canceled';
        processedResult.detail = cancelDetails;
        break;
      case SpeechSDK.ResultReason.RecognizedSpeech:
      case SpeechSDK.ResultReason.TranslatedSpeech:
      case SpeechSDK.ResultReason.RecognizedIntent:
        if (this.useDetailedResults) {
          const detailedResultJson = JSON.parse(result.json);
          console.log(`Detailed result for "${detailedResultJson['DisplayText']}":`, JSON.stringify(detailedResultJson, null, 2));
          processedResult = detailedResultJson;
        } else if (result.text) {
          console.log(`${result.text}`);
          processedResult = { text: result.text };
        }

        const intentJson = result.properties.getProperty(SpeechSDK.PropertyId.LanguageUnderstandingServiceResponse_JsonResult);
        if (intentJson) {
          console.log(`${intentJson}`);
          processedResult.intent = JSON.parse(intentJson);
        }
        break;
    }

    return processedResult; // Return the processed result
  };

  onSessionStarted = (sender: Recognizer, sessionEventArgs: SessionEventArgs) => {
    console.log(`(sessionStarted) SessionId: ${sessionEventArgs.sessionId}\r\n`);
  };

  onSessionStopped = (sender: Recognizer, sessionEventArgs: SessionEventArgs) => {
    console.log(`(sessionStopped) SessionId: ${sessionEventArgs.sessionId}\r\n`);
  };

  onCanceled = (sender: Recognizer, cancellationEventArgs: SpeechRecognitionCanceledEventArgs) => {
    window.console.log('(cancel) Reason: ' + SpeechSDK.CancellationReason[cancellationEventArgs.reason]);
  };

  private grammarPhrases?: string[] = [];
  setGrammarPhrases = (grammarPhrases: string[]) => {
    this.grammarPhrases = grammarPhrases;
  };

  applyCommonConfigurationTo = (recognizer: SpeechRecognizer) => {
    recognizer.recognizing = this.onRecognizing;

    recognizer.recognized = this.onRecognized;

    recognizer.canceled = this.onCanceled;

    recognizer.sessionStarted = this.onSessionStarted;

    recognizer.sessionStopped = this.onSessionStopped;

    if (this.grammarPhrases && this.grammarPhrases?.length > 0) {
      const phraseListGrammar = SpeechSDK.PhraseListGrammar.fromRecognizer(recognizer);
      phraseListGrammar.addPhrases(this.grammarPhrases);
    }
  };
}

export const speechService = new SpeechService();
export default speechService;
