import ReactGA from 'react-ga4';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';

const TRACKING_ID = 'G-G2LDD4ZCDS';
const isNativePlatform = Capacitor.isNativePlatform();

class AnalyticsService {
  initializeWebAnalytics() {
    ReactGA.initialize(TRACKING_ID);
  }

  async initializeMobileAnalytics() {
    await FirebaseAnalytics.setEnabled({ enabled: true });
  }

  initializeAnalytics() {
    if (isNativePlatform) {
      this.initializeMobileAnalytics();
    } else {
      this.initializeWebAnalytics();
    }
  }

  async setCurrentPage(pathName: string, pageTitle: string) {
    if (isNativePlatform) {
      await FirebaseAnalytics.setCurrentScreen({ screenName: pageTitle });
    } else {
      ReactGA.send({ hitType: 'pageview', page: pathName, title: pageTitle });
    }
  }

  async setUserCommunity(communityName: string) {
    if (isNativePlatform) {
      await FirebaseAnalytics.setUserProperty({
        key: 'community',
        value: communityName,
      });
    } else {
      ReactGA.gtag('set', 'user_properties', {
        community: communityName,
      });
    }
  }
}

export const analyticsService = new AnalyticsService();
export default analyticsService;
