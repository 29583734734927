export enum CitationStatus {
  PayNow = 'PayNow',
  Warning = 'Warning',
  Pending = 'Pending',
  PastDue = 'PastDue',
  Delinquent = 'Delinquent',
  Canceled = 'Canceled',
  Paid = 'Paid',
}

export const citationStatusMap: Record<CitationStatus, string> = {
  [CitationStatus.PayNow]: 'Pay Now',
  [CitationStatus.Warning]: 'Warning',
  [CitationStatus.Pending]: 'Pending',
  [CitationStatus.PastDue]: 'Past Due',
  [CitationStatus.Delinquent]: 'Delinquent',
  [CitationStatus.Canceled]: 'Canceled',
  [CitationStatus.Paid]: 'Paid',
};

export const citationStatusColorMap: Record<CitationStatus, string> = {
  [CitationStatus.PayNow]: '#C1FFEA',
  [CitationStatus.Warning]: '#FFD889',
  [CitationStatus.Pending]: '#DCDCDC',
  [CitationStatus.PastDue]: '#FFA39E',
  [CitationStatus.Delinquent]: '#FFD889',
  [CitationStatus.Canceled]: '#DCDCDC',
  [CitationStatus.Paid]: '#C1FFEA',
};
