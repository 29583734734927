import React from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarLinkProps } from './sidebar-link.types';
import './sidebar-link.scss';

export const SidebarLink = ({ className, label, icon, activeIcon, to, ...rest }: SidebarLinkProps) => {
  return (
    <NavLink className={`sidebar-link ${className ?? ''}`} to={to} {...rest}>
      <span className="sidebar-link__icon">{icon}</span>
      <span className="sidebar-link__icon active">{activeIcon}</span>
      <span className="sidebar-link__label">{label}</span>
    </NavLink>
  );
};
