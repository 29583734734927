import React, { FC } from 'react';
import { Button, Modal, Space } from 'antd';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ICheckoutModalProps } from './checkout-modal.types';
import { IModal } from 'common/models/model.interface';
import { notification } from 'common/utils/notification';

export const CheckoutModal: FC<IModal<ICheckoutModalProps, any>> = ({ isOpen, onClose, title, initData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const hideModal = (isSuccess: boolean) => {
    notification.destroy();
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {},
      redirect: 'if_required',
    });

    if (result.error) {
      console.log(result.error.message);
      notification.error({
        duration: 5,
        message: 'Some error occurred',
      });
    } else {
      hideModal(true);
    }
  };

  return (
    <Modal
      centered
      title={title}
      width={420}
      open={isOpen}
      wrapClassName="custom-modal"
      className="confirmation-modal vms-vehicle-delete-modal"
      closable={false}>
      <form onSubmit={handleSubmit}>
        <PaymentElement options={{ layout: 'accordion' }} />
        <Space direction="vertical" className="full-width btn-container">
          <Button type="primary" className="full-width" disabled={!stripe} htmlType="submit">
            Pay ${initData?.totalPaymentAmount}
          </Button>
          <Button type="link" className="full-width" onClick={() => hideModal(false)}>
            Cancel
          </Button>
        </Space>
      </form>
    </Modal>
  );
};
